import { AxiosProgressEvent } from 'axios'
import { api } from '../config/AxiosConfig'
import {
  AddReviewerData,
  CreateLabelProofApiData,
  CreateTaskApiData,
  GetLabelProofCreatedByFilterApiParams,
  GetLabelProofsApiParams,
  ReviewTaskData,
  UpdateLabelProofApiData,
  UpdateTaskApiData
} from './LabelProofApiRequest'
import {
  LabelProofCommentApiResponse,
  LabelProofApiResponse,
  LabelProofsApiPaginatedResponse,
  LabelProofsCreatedByApiResponse,
  TaskApiResponse,
  AddReviewerApiResponse,
  PaginatedLabelProofCommentApiResponse,
  LabelProofCommentHavingPinApiResponse,
  PaginatedLabelProofActivityApiResponse
} from './LabelProofApiResponse'
import { FileApi } from '../document/DocumentApi'
import {
  CreateProofCommentRequestData,
  UpdateProofCommentRequestData
} from 'state/labelproof/proofer/ProoferRequest'

export const LabelProofsApi = {
  getLabelProofs: (
    companyId: string,
    params: GetLabelProofsApiParams
  ): Promise<LabelProofsApiPaginatedResponse> => {
    return api
      .get<LabelProofsApiPaginatedResponse>(
        `/companies/${companyId}/label_proofs`,
        {
          params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return res.data
      })
  },
  createLabelProof: (
    companyId: string,
    data: CreateLabelProofApiData
  ): Promise<LabelProofApiResponse> => {
    return api
      .post<LabelProofApiResponse>(`/companies/${companyId}/label_proofs`, data)
      .then((res) => {
        return res.data
      })
  },
  updateLabelProof: (
    companyId: string,
    proofId: string,
    data: UpdateLabelProofApiData
  ): Promise<LabelProofApiResponse> => {
    return api
      .put<LabelProofApiResponse>(
        `/companies/${companyId}/label_proofs/${proofId}`,
        data
      )
      .then((res) => {
        return res.data
      })
  },
  deleteLabelProof: (
    companyId: string,
    labelProofId: string
  ): Promise<string> => {
    return api
      .delete<void>(`/companies/${companyId}/label_proofs/${labelProofId}`)
      .then(() => {
        return labelProofId
      })
  },
  getLabelProof: (
    companyId: string,
    labelProofId: string
  ): Promise<LabelProofApiResponse> => {
    return api
      .get<LabelProofApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}`
      )
      .then((res) => {
        return res.data
      })
  },
  getLabelProofCreatedByFilter: (
    company_id: string,
    params: GetLabelProofCreatedByFilterApiParams
  ): Promise<LabelProofsCreatedByApiResponse> => {
    return api
      .get<LabelProofsCreatedByApiResponse>(
        `/companies/${company_id}/filters/label_proofs/created_by`,
        {
          params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return res.data
      })
  },
  uploadLabelProofDesignFile: async (
    companyId: string,
    labelProofId: string,
    file: File,
    uploadProgressListener?: (
      progressEvent: AxiosProgressEvent
    ) => ProgressEvent | void
  ): Promise<string> => {
    return api
      .post<string>(
        `/companies/${companyId}/label_proofs/${labelProofId}/documents`,
        { fileName: file.name, contentType: file.type }
      )
      .then((res) => {
        return FileApi.uploadFile(file, res.data, uploadProgressListener)
      })
  },

  getLabelProofDesignFile: async (
    companyId: string,
    labelProofId: string
  ): Promise<string> => {
    return api
      .get<string>(
        `/companies/${companyId}/label_proofs/${labelProofId}/documents`
      )
      .then((res) => {
        return res.data
      })
  },

  getLabelProofCommentsHavingPin: async (
    companyId: string,
    labelProofId: string
  ): Promise<LabelProofCommentHavingPinApiResponse[]> => {
    return api
      .get<LabelProofCommentHavingPinApiResponse[]>(
        `/companies/${companyId}/label_proofs/${labelProofId}/comments/pins`
      )
      .then((res) => {
        return res.data
      })
  },

  getLabelProofComments: async (
    companyId: string,
    labelProofId: string,
    page?: number,
    size?: number
  ): Promise<PaginatedLabelProofCommentApiResponse> => {
    return api
      .get<PaginatedLabelProofCommentApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/comments`,
        {
          params: { page, size }
        }
      )
      .then((res) => {
        return res.data
      })
  },

  createLabelProofComment: async (
    companyId: string,
    labelProofId: string,
    data: CreateProofCommentRequestData
  ): Promise<LabelProofCommentApiResponse> => {
    return api
      .post<LabelProofCommentApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/comments`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  updateLabelProofComment: async (
    companyId: string,
    labelProofId: string,
    commentId: string,
    data: UpdateProofCommentRequestData
  ): Promise<LabelProofCommentApiResponse> => {
    return api
      .put<LabelProofCommentApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/comments/${commentId}`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  deleteLabelProofComment: async (
    companyId: string,
    labelProofId: string,
    commentId: string
  ): Promise<LabelProofCommentApiResponse> => {
    return api
      .delete<LabelProofCommentApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/comments/${commentId}`
      )
      .then((res) => {
        return res.data
      })
  },

  getTasks: async (
    companyId: string,
    labelProofId: string
  ): Promise<TaskApiResponse[]> => {
    return api
      .get<TaskApiResponse[]>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks`
      )
      .then((res) => {
        return res.data
      })
  },

  createTask: async (
    companyId: string,
    labelProofId: string,
    data: CreateTaskApiData
  ): Promise<TaskApiResponse> => {
    return api
      .post<TaskApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  updateTask: async (
    companyId: string,
    labelProofId: string,
    taskId: string,
    data: UpdateTaskApiData
  ): Promise<TaskApiResponse> => {
    return api
      .put<TaskApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks/${taskId}`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  deleteTask: async (
    companyId: string,
    labelProofId: string,
    taskId: string
  ): Promise<void> => {
    return api
      .delete<void>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks/${taskId}`
      )
      .then((res) => {
        return res.data
      })
  },

  addReviewer: async (
    companyId: string,
    labelProofId: string,
    taskId: string,
    data: AddReviewerData
  ): Promise<AddReviewerApiResponse> => {
    return api
      .post<AddReviewerApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks/${taskId}/reviewers`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  reviewTask: async (
    companyId: string,
    labelProofId: string,
    taskId: string,
    data: ReviewTaskData
  ): Promise<AddReviewerApiResponse> => {
    return api
      .patch<AddReviewerApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks/${taskId}/review`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  removeReviewer: async (
    companyId: string,
    labelProofId: string,
    taskId: string,
    reviewerId: string
  ): Promise<void> => {
    return api
      .delete<void>(
        `/companies/${companyId}/label_proofs/${labelProofId}/tasks/${taskId}/reviewers/${reviewerId}`
      )
      .then((res) => {
        return res.data
      })
  },

  getLabelProofActivities: async (
    companyId: string,
    labelProofId: string,
    page?: number,
    size?: number
  ): Promise<PaginatedLabelProofActivityApiResponse> => {
    return api
      .get<PaginatedLabelProofActivityApiResponse>(
        `/companies/${companyId}/label_proofs/${labelProofId}/activity`,
        {
          params: { page, size }
        }
      )
      .then((res) => {
        return res.data
      })
  }
}
