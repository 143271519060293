import { Box, InputAdornment, Typography } from '@mui/material'
import { convertToFixedFloat, pluralize } from 'common/utils'
import { InputFieldInnerLabel } from 'components/InputFieldInnerLabel/InputFieldInnerLabel'
import { NumberField } from 'components/InputFields/NumberField'
import { BoxRow, BoxRowBasic } from 'components/common'

export interface TotalsBarProps {
  totalIngredients: number
  servingWeight: {
    value: number
    onChange: (value: number) => void
  }
  totalWeight: {
    value: number
    onChange: (value: number) => void
  }
  cost: {
    totalCostPerServing: number | null
    totalCostPerWeight: number | null
    visibile: boolean
  }
}

export const TotalsBar: React.FC<TotalsBarProps> = ({
  totalIngredients,
  servingWeight,
  totalWeight,
  cost
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: 'white'
      }}
    >
      <Box sx={{ flex: 3 }}>
        <Typography color="secondary">
          {totalIngredients} {pluralize(totalIngredients, 'ingredient')}
        </Typography>
      </Box>
      <BoxRow gap="10px" sx={{ flex: 7, justifyContent: 'flex-end' }}>
        {cost.visibile && (
          <BoxRowBasic>
            <Typography
              color="secondary"
              style={{ marginRight: '10px', whiteSpace: 'nowrap' }}
            >
              Serving Cost
            </Typography>
            <Typography>
              {cost.totalCostPerServing
                ? `$${convertToFixedFloat(cost.totalCostPerServing, 2)}`
                : '$0'}
            </Typography>
          </BoxRowBasic>
        )}
        <Box>
          <NumberField
            onChange={servingWeight.onChange}
            value={servingWeight.value}
            maxDecimals={6}
            debounceTime={500}
            startAdornment={
              <InputAdornment position="start">Serving Size</InputAdornment>
            }
            endAdornment={<InputAdornment position="end">g</InputAdornment>}
            scale="small"
            sx={{ padding: '6px 10px' }}
            inputProps={{
              style: { textAlign: 'right' }
            }}
          />
        </Box>
        <Box>
          <InputFieldInnerLabel
            label="Total"
            initialValue={(totalWeight.value || 0)?.toString()}
            endAdornment={<InputAdornment position="end">g</InputAdornment>}
            liveChanges={false}
            onChange={(v) => totalWeight.onChange(v ? Number(v) : 100)}
          />
        </Box>
      </BoxRow>
    </Box>
  )
}
