import { Comment, CommentProps } from 'components/Comments/Comment'
import { mentionParser, pinParser } from 'components/Comments/TextParsers'
import React from 'react'
import { ProofLogProps } from '../ProofLog/ProofLog'

export interface ProofCommentProps extends Omit<CommentProps, 'parsers'> {
  mentionedUsersMap: Record<string, string>
  pinsMap: Record<string, string>
  onPinClick: (id: string) => void
}

export const isProofCommentProps = (props: ProofCommentProps | ProofLogProps): props is ProofCommentProps => {
  return "mentionedUsersMap" in props
}

export const ProofComment: React.FC<ProofCommentProps> = ({
  mentionedUsersMap,
  pinsMap,
  onPinClick,
  ...rest
}) => {
  return (
    <Comment
      {...rest}
      parsers={[
        mentionParser(mentionedUsersMap),
        pinParser(pinsMap, onPinClick)
      ]}
      />
  )
}
