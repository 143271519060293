import { ActiveIngredientDisplaySection } from 'models/FormulaIngredient'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setNutritionFactLabelEdits } from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { ActiveIngredientsDisplay } from './ActiveIngredientsDisplay'

export const ActiveIngredientsDisplayContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const activeIngredientsSectionDisplayPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .activeIngredientsSectionDisplay
  )

  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )

  const onDisplaySectionChange = (
    id: string,
    displaySection: ActiveIngredientDisplaySection
  ) => {
    if (activeIngredientsSectionDisplayPreview) {
      const updatedActiveIngredientsDisplayPreview =
        activeIngredientsSectionDisplayPreview.map((ingredient) =>
          ingredient.id === id ? { ...ingredient, displaySection } : ingredient
        )
      dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          activeIngredientsSectionDisplay:
            updatedActiveIngredientsDisplayPreview
        })
      )
    }
  }

  return (
    <ActiveIngredientsDisplay
      activeIngredientsListing={activeIngredientsSectionDisplayPreview || []}
      displaySectionOptions={Object.values(ActiveIngredientDisplaySection).map(
        (value) => ({
          label: value,
          value: value as ActiveIngredientDisplaySection
        })
      )}
      onDisplaySectionChange={onDisplaySectionChange}
    />
  )
}
