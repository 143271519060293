import { Box } from '@mui/material'
import { Button } from 'components/Button/Button'
import {
  Avatar,
  AvatarProps
} from 'pages/LabelProofs/LabelProofer/components/Panel/components/Avatar'
import ClearIcon from '@mui/icons-material/Clear'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import React from 'react'
import { MainColors } from 'styles/colors'

export interface ReviewerProps extends AvatarProps {
  onRemove: (id: string) => void
  removable?: boolean
  owner?: boolean
}

const BlueStarIcon: React.FC = () => {
  return (
    <Box
      sx={{
        width: '13px',
        height: '13px',
        backgroundColor: MainColors.primary,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <StarRoundedIcon sx={{ width: '10px', height: '10px', color: 'white' }} />
    </Box>
  )
}

export const Reviewer: React.FC<ReviewerProps> = ({
  onRemove,
  removable = true,
  owner = false,
  ...rest
}) => {
  const [removeVisibile, setRemoveVisible] = React.useState(false)

  const handleMouseEnter = () => {
    if (removable) {
      setRemoveVisible(true)
    }
  }

  const handleMouseLeave = () => {
    setRemoveVisible(false)
  }

  return (
    <Box
      key={`reviewer-${rest.id}`}
      display={'inline-block'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      position={'relative'}
    >
      {owner && (
        <Box
          sx={{
            position: 'absolute',
            right: '-4px',
            top: '-4px',
            zIndex: '1'
          }}
        >
          <BlueStarIcon />
        </Box>
      )}
      {removeVisibile && (
        <Box
          sx={{ position: 'absolute', right: '-5px', top: '-8px', zIndex: '2' }}
        >
          <Button
            sx={{ width: '10px', height: '10px' }}
            variant="circle"
            color={'danger'}
            onClick={() => onRemove(rest.id)}
          >
            <ClearIcon sx={{ width: '10px', height: '10px' }} />
          </Button>
        </Box>
      )}
      <Avatar {...rest} />
    </Box>
  )
}
