import React from 'react'
import { FormulatorPercentageField } from '../../../../../../../FormulatorStyles'

export interface IngredientWastePercentageProps {
  value: number
  onChange: (value: number) => void
  disabled?: boolean
}

export const IngredientWastePercentage: React.FC<
  IngredientWastePercentageProps
> = ({ value, onChange, disabled = false }) => {
  return (
    <FormulatorPercentageField
      disabled={disabled}
      value={value}
      onChange={onChange}
      maxDecimals={2}
      debounceTime={500}
    />
  )
}
