import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { SubFormulas } from './SubFormulas'
import { SubFormulaStatementFormat } from 'models/Formula'
import {
  setFormulaIngredientsStatementFormatPreview
} from 'state/labels/statements/IngredientStatementsSlice'
import { mapFormulaIngredientsToStatementFormatsPreview } from 'services/apis/formula/FormulaApiMapper'

export const SubFormulasContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const formulaIngredients = useAppSelector(
    (state) => state.formulatorIngredients.formulaIngredients
  )

  const formulaIngredientsStatementFormatPreview = useAppSelector(
    (state) =>
      state.ingredientStatements.formulaIngredientsStatementFormatPreview
  )

  const formulaIsSupplement = useAppSelector(
    (state) => state.formulator.formula.isSupplement
  )
  const formulaIsSupplementEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )

  React.useEffect(() => {
    if (formulaIngredientsStatementFormatPreview
      && formulaIngredientsStatementFormatPreview.length > 0) {
        return
      }

    const formatPreview = mapFormulaIngredientsToStatementFormatsPreview(
      formulaIsSupplement || formulaIsSupplementEdits,
      formulaIngredients
    )
    dispatch(setFormulaIngredientsStatementFormatPreview(formatPreview))
  }, [formulaIngredients])

  const onFormatChange = (id: string, format: SubFormulaStatementFormat) => {
    if (formulaIngredientsStatementFormatPreview) {
      const updatedFormatPreview = formulaIngredientsStatementFormatPreview.map(
        (ingredient) =>
          ingredient.id === id ? { ...ingredient, format } : ingredient
      )
      dispatch(
        setFormulaIngredientsStatementFormatPreview(updatedFormatPreview)
      )
    }
  }

  return (
    <SubFormulas
      formulaIngredientsListing={formulaIngredientsStatementFormatPreview || []}
      formatOptions={Object.values(SubFormulaStatementFormat).map((value) => ({
        label: value,
        value: value as SubFormulaStatementFormat
      }))}
      onFormatChange={onFormatChange}
    />
  )
}
