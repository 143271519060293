import React from 'react'
import { LayoutContainer } from './components/Layout/LayoutContainer'
import { NutrientsSettingsContainer } from './components/NutrientsSettings/NutrientsSettingsContainer'
import { OptionalNutrientsContainer } from './components/OptionalNutrients/OptionalNutrientsContainer'
import { General } from './General'
import { SubFormulasContainer } from './components/SubFormulas/SubFormulasContainer'
import { ActiveIngredientsDisplayContainer } from './components/ActiveIngredientsDisplay/ActiveIngredientsDisplayContainer'

export const GeneralContainer: React.FC = ({}) => {
  return (
    <General
      layout={<LayoutContainer />}
      subFormulas={<SubFormulasContainer />}
      activeIngredientsDisplay={<ActiveIngredientsDisplayContainer />}
      nutrientsSettings={<NutrientsSettingsContainer />}
      optionalNutrients={<OptionalNutrientsContainer />}
    />
  )
}
