import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import { MainColors } from 'styles/colors'
import { SxProps } from '@mui/material'

export interface DotStatusProps {
  color?: (typeof MainColors)[keyof typeof MainColors]
  sx?: SxProps
}

export const DotStatus: React.FC<DotStatusProps> = ({
  color = MainColors.warning,
  sx
}) => {
  return (
    <CircleIcon sx={{ width: '10px', height: '10px', color: color, ...sx }} />
  )
}
