import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Activities } from './Activities'
import {
  setHighlightedPinId,
} from 'state/labelproof/proofer/ProoferSlice'
import {
  createProofComment,
  deleteProofComment,
  getProofActivities,
  getProofCommentsHavingPin,
  isComment,
  resetProoferActivitiesState,
  resetStagedComment,
  setEditingComment,
  setPinMode,
  setStagedComment,
  togglePinMode,
  updateProofComment
} from 'state/labelproof/proofer/ProoferActivitiesSlice'
import { CreateProofCommentRequestData, UpdateProofCommentRequestData } from 'state/labelproof/proofer/ProoferRequest'
import { ModalContext } from 'components/Modal/ModalContext'
import { useLoadNextPage } from 'common/hooks'
import {
  mapCommentReplyToStagedCommentReply,
  mapCommentToStagedComment,
  mapActivitiesToCommentsAndLogsProps
} from './ActivitiesMappers'

export const ActivitiesContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const proofId = useAppSelector((state) => state.proofer.proof?.id)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const loading = useAppSelector((state) => state.prooferActivities.loadingActivities)
  const adding = useAppSelector((state) => state.prooferActivities.addingComment)
  const activities = useAppSelector((state) => state.prooferActivities.activities.items)
  const currentPage = useAppSelector((state) => state.prooferActivities.activities.page)
  const pages = useAppSelector((state) => state.prooferActivities.activities.pages)
  const pinMode = useAppSelector((state) => state.prooferActivities.pinMode)

  const { showConfirmationModal } = React.useContext(ModalContext)

  const activitiesLoading = React.useMemo(() => {
    return loading && activities.length === 0
  }, [loading, activities])

  const commentEditDisabled = React.useMemo(() => {
    return adding || loading
  }, [adding, loading])

  const setCurrentPageCallback = React.useCallback(
    (page: number) => {
      if (currentPage >= page) {
        return
      }
      if (proofId) {
        void dispatch(
          getProofActivities({
            companyId,
            proofId,
            page
          })
        )
      }
    },
    [companyId, proofId]
  )

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page: currentPage,
    pages,
    setCurrentPageCallback
  })

  // Initially get the first page of activities
  React.useEffect(() => {
    if (proofId && activities.length === 0) {
      void dispatch(
        getProofActivities({
          companyId,
          proofId
        })
      )
      void dispatch(
        getProofCommentsHavingPin({
          companyId,
          proofId: proofId
        })
      )
    }
    return () => {
      dispatch(resetProoferActivitiesState({keepPins: true}))
    }
  }, [companyId, proofId])

  const handleSendComment = React.useCallback(
    async (comment: CreateProofCommentRequestData): Promise<void> => {
      if (proofId) {
        await dispatch(
          createProofComment({
            companyId,
            proofId,
            data: comment
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetStagedComment())
            dispatch(setPinMode(false))
          })
      }
    },
    [companyId, dispatch, proofId]
  )

  const handleUpdateComment = React.useCallback(
    async (commentId: string, comment: UpdateProofCommentRequestData) => {
      if (proofId) {
        await dispatch(
          updateProofComment({
            companyId,
            proofId,
            commentId,
            data: comment
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetStagedComment())
            dispatch(setPinMode(false))
          })
      }
    },
    [companyId, dispatch, proofId]
  )

  const onDeleteClick = React.useCallback(
    (commentId: string) => {
      if (proofId) {
        showConfirmationModal({
          title: 'Delete Comment',
          message: <>Are you sure you want to delete this comment?</>,
          yesText: 'Delete',
          noText: 'Cancel',
          onYesClicked: () => {
            void dispatch(deleteProofComment({ companyId, proofId, commentId }))
          },
          danger: true
        })
      }
    },
    [dispatch]
  )

  const onEditClick = (id: string) => {
    const activity = activities.find((activity) => activity.id === id)
    if (activity && isComment(activity)) {
      dispatch(setStagedComment(mapCommentToStagedComment(activity)))
      dispatch(setEditingComment(true))
      dispatch(setPinMode(!!activity.pin))
    }
  }

  const onReplyClick = (id: string) => {
    const comment = activities.find((activity) => activity.id === id)
    if (comment && isComment(comment)) {
      dispatch(
        setStagedComment({
          replyTo: mapCommentReplyToStagedCommentReply(comment)
        })
      )
    }
  }

  const onPinClick = React.useCallback(
    (id: string) => {
      dispatch(setHighlightedPinId(id))
    },
    [dispatch]
  )

  const handleTogglePinMode = React.useCallback(() => {
    dispatch(togglePinMode())
  }, [dispatch])

  const onRemoveReply = React.useCallback(() => {
    dispatch(
      setStagedComment({
        replyTo: undefined
      })
    )
  }, [dispatch])

  const activitiesProps = React.useMemo(() => {
    return mapActivitiesToCommentsAndLogsProps(
      activities,
      currentUser.id,
      onDeleteClick,
      onEditClick,
      onReplyClick,
      onPinClick
    )
  }, [activities])

  return (
    <Activities
      loading={activitiesLoading}
      activitiesProps={activitiesProps}
      proofCommentFormProps={{
        onSubmit: handleSendComment,
        onUpdate: handleUpdateComment,
        togglePinMode: handleTogglePinMode,
        onRemoveReply: onRemoveReply,
        pinMode: pinMode,
        disabled: commentEditDisabled
      }}
      nextPageCallback={nextPageCallback}
      allPagesLoaded={allPagesLoaded}
      page={currentPage}
    />
  )
}
