import { Box } from '@mui/material'
import React from 'react'

interface GeneralProps {
  layout: React.ReactNode
  subFormulas: React.ReactNode
  activeIngredientsDisplay: React.ReactNode
  nutrientsSettings: React.ReactNode
  optionalNutrients: React.ReactNode
}

export const General: React.FC<GeneralProps> = ({
  layout,
  subFormulas,
  activeIngredientsDisplay,
  nutrientsSettings,
  optionalNutrients
}) => {
  return (
    <Box sx={{ height: '100%' }}>
      {layout}
      {subFormulas}
      {activeIngredientsDisplay}
      {nutrientsSettings}
      {optionalNutrients}
    </Box>
  )
}
