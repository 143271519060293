import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  LabelProofActionApiResponse,
  LabelProofActivityApiResponse,
  LabelProofCommentApiResponse,
  LabelProofCommentHavingPinApiResponse,
  PaginatedLabelProofActivityApiResponse,
  PinApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import {
  GetProofActivitiesRequest,
  GetProofCommentsHavingPinRequest,
  CreateProofCommentRequest,
  DeleteProofCommentRequest,
  UpdateProofCommentRequest,
} from './ProoferRequest'
import { buildPinId } from './helpers'
import { BaseApiPaginatedResponse } from 'services/apis/config/BaseApiPaginatedResponse'


export type Activity = LabelProofCommentApiResponse | LabelProofActivityApiResponse
export type PaginatedActivities = BaseApiPaginatedResponse<
  Activity
>
export const isLog = (item: Activity): item is LabelProofActivityApiResponse => {
  return 'details' in item
}
export const isComment = (item: Activity): item is LabelProofCommentApiResponse => {
  return 'comment' in item
}

export interface StagedCommentReplyTo {
  id: string
  comment: string
  mentionsMap: Record<string, string>
  createdByName?: string
  pinMap: Record<string, string>
  pinId?: string
}

export interface StagedComment {
  id?: string
  comment: string
  replyTo?: StagedCommentReplyTo
  pin?: PinApiResponse
  mentionedUserIds?: string[]
}

export interface ActivitiesState {
  activities: PaginatedActivities
  stagedComment: StagedComment
  pinMode: boolean
  editingComment: boolean
  addingComment: boolean
  loadingComments: boolean
  loadingActivities: boolean
  commentsHavingPin?: LabelProofCommentHavingPinApiResponse[]
}

const initialState: ActivitiesState = {
  activities: {
    items: [],
    page: 0,
    total: 0,
    size: 0,
    pages: 0
  },
  stagedComment: {
    comment: '',
    replyTo: undefined,
    pin: undefined,
    mentionedUserIds: undefined
  },
  pinMode: false,
  editingComment: false,
  addingComment: false,
  loadingComments: false,
  loadingActivities: false
}

export const getProofCommentsHavingPin = createAsyncThunk(
  'labelproof/proofer/getCommentsHavingPin',
  async ({ companyId, proofId }: GetProofCommentsHavingPinRequest) => {
    return await LabelProofsApi.getLabelProofCommentsHavingPin(
      companyId,
      proofId
    )
  }
)

export const getProofActivities = createAsyncThunk(
  'labelproof/proofer/getActivities',
  async ({
    companyId,
    proofId,
    page = 1,
    size = 50
  }: GetProofActivitiesRequest) => {
    return await LabelProofsApi.getLabelProofActivities(
      companyId,
      proofId,
      page,
      size
    )
  }
)

export const createProofComment = createAsyncThunk(
  'labelproof/proofer/createComment',
  async ({ companyId, proofId, data }: CreateProofCommentRequest) => {
    return await LabelProofsApi.createLabelProofComment(
      companyId,
      proofId,
      data
    )
  }
)

export const updateProofComment = createAsyncThunk(
  'labelproof/proofer/updateComment',
  async ({
    companyId,
    proofId,
    commentId,
    data
  }: UpdateProofCommentRequest) => {
    return await LabelProofsApi.updateLabelProofComment(
      companyId,
      proofId,
      commentId,
      data
    )
  }
)

export const deleteProofComment = createAsyncThunk(
  'labelproof/proofer/deleteComment',
  async ({ companyId, proofId, commentId }: DeleteProofCommentRequest) => {
    return await LabelProofsApi.deleteLabelProofComment(
      companyId,
      proofId,
      commentId
    )
  }
)

const prooferActivitiesSlice = createSlice({
  name: 'prooferActivitiesSlice',
  initialState,
  reducers: {
    togglePinMode: (state) => {
      state.pinMode = !state.pinMode
    },
    setPinMode: (state, action: PayloadAction<boolean>) => {
      state.pinMode = action.payload
    },
    setStagedComment: (
      state,
      action: PayloadAction<Partial<StagedComment>>
    ) => {
      state.stagedComment = {
        ...state.stagedComment,
        ...action.payload
      }
    },
    resetStagedComment: (state) => {
      state.stagedComment = initialState.stagedComment
      state.pinMode = false
    },
    setEditingComment: (state, action: PayloadAction<boolean>) => {
      state.editingComment = action.payload
    },
    resetProoferActivitiesState: (state, action: PayloadAction<{ keepPins: boolean } | undefined>) => {
      return {
        ...initialState,
        commentsHavingPin: action.payload && action.payload.keepPins
          ? state.commentsHavingPin
          : undefined
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(getProofCommentsHavingPin.pending, (state) => {
      state.loadingComments = true
    })
    builder.addCase(
      getProofCommentsHavingPin.fulfilled,
      (
        state,
        action: PayloadAction<LabelProofCommentHavingPinApiResponse[]>
      ) => {
        state.loadingComments = false
        state.commentsHavingPin = action.payload.map((comment) => {
          comment.pin.id = buildPinId(
            comment.pin.widthRatio,
            comment.pin.heightRatio
          )
          return comment
        })
      }
    )
    builder.addCase(getProofCommentsHavingPin.rejected, (state) => {
      state.commentsHavingPin = undefined
      state.loadingComments = false
    })

    builder.addCase(getProofActivities.pending, (state) => {
      state.loadingActivities = true
    })
    builder.addCase(
      getProofActivities.fulfilled,
      (
        state,
        action: PayloadAction<PaginatedLabelProofActivityApiResponse>
      ) => {
        action.payload.items = action.payload.items.map((item) => {
          if (
            item.details.action === LabelProofActionApiResponse.COMMENT_CREATED
          ) {
            if (item.linkedComment) {
              if (item.linkedComment.pin) {
                item.linkedComment.pin.id = buildPinId(
                  item.linkedComment.pin.widthRatio,
                  item.linkedComment.pin.heightRatio
                )
              }
              if (
                item.linkedComment.replyTo &&
                item.linkedComment.replyTo.pin
              ) {
                item.linkedComment.replyTo.pin.id = buildPinId(
                  item.linkedComment.replyTo.pin.widthRatio,
                  item.linkedComment.replyTo.pin.heightRatio
                )
              }
            }
          }
          return item
        })
        const newActivitiesAndComments = action.payload.items
          .filter((item) => ![
            LabelProofActionApiResponse.COMMENT_DELETED,
            LabelProofActionApiResponse.COMMENT_EDITED
          ].includes(item.details.action))
          .map((item) => {
            if (
              item.details.action ===
              LabelProofActionApiResponse.COMMENT_CREATED &&
              item.linkedComment
            ) {
              return item.linkedComment
            }
            return item
          })
        state.activities = {
          ...action.payload,
          items: [...newActivitiesAndComments, ...state.activities.items]
        }
        state.loadingActivities = false
      }
    )
    builder.addCase(getProofActivities.rejected, (state) => {
      state.loadingActivities = false
    })

    builder.addCase(createProofComment.pending, (state) => {
      state.addingComment = true
    })
    builder.addCase(
      createProofComment.fulfilled,
      (state, action: PayloadAction<LabelProofCommentApiResponse>) => {
        state.addingComment = false

        if (action.payload.pin && state.commentsHavingPin) {
          action.payload.pin.id = buildPinId(
            action.payload.pin.widthRatio,
            action.payload.pin.heightRatio
          )
          state.commentsHavingPin = [
            ...state.commentsHavingPin,
            action.payload as LabelProofCommentHavingPinApiResponse
          ]
        }

        state.activities.items = [...state.activities.items, action.payload]
      }
    )
    builder.addCase(createProofComment.rejected, (state) => {
      state.addingComment = false
    })

    builder.addCase(updateProofComment.pending, (state) => { })
    builder.addCase(
      updateProofComment.fulfilled,
      (state, action: PayloadAction<LabelProofCommentApiResponse>) => {
        if (state.commentsHavingPin) {
          if (action.payload.pin) {
            action.payload.pin.id = buildPinId(
              action.payload.pin.widthRatio,
              action.payload.pin.heightRatio
            )
            const existingHavingPin = state.commentsHavingPin.find(
              (comment) => comment.id === action.payload.id
            )
            if (existingHavingPin) {
              state.commentsHavingPin = state.commentsHavingPin.map(
                (comment) => {
                  if (comment.id === action.payload.id) {
                    return action.payload as LabelProofCommentHavingPinApiResponse
                  }
                  return comment
                }
              )
            } else {
              state.commentsHavingPin = [
                ...state.commentsHavingPin,
                action.payload as LabelProofCommentHavingPinApiResponse
              ]
            }
          } else {
            state.commentsHavingPin = state.commentsHavingPin.filter(
              (comment) => comment.id !== action.payload.id
            )
          }
        }

        state.activities.items = state.activities.items.map((activity) => {
          if (activity.id === action.payload.id) {
            return action.payload
          }
          if (
            isComment(activity)
            && activity.replyTo
            && activity.replyTo.id === action.payload.id
          ) {
            return {
              ...activity,
              replyTo: action.payload
            }
          }
          return activity
        })
      }
    )
    builder.addCase(updateProofComment.rejected, (state) => { })

    builder.addCase(deleteProofComment.pending, (state) => { })
    builder.addCase(
      deleteProofComment.fulfilled,
      (state, action: PayloadAction<LabelProofCommentApiResponse>) => {
        if (state.commentsHavingPin) {
          state.commentsHavingPin = state.commentsHavingPin.filter(
            (comment) => comment.id !== action.payload.id
          )
        }

        state.activities.items = state.activities.items.map((activity) => {
          if (activity.id === action.payload.id) {
            return action.payload
          }
          if (
            isComment(activity) &&
            activity.replyTo &&
            activity.replyTo.id === action.payload.id
          ) {
            return {
              ...activity,
              replyTo: action.payload,
            }
          }
          return activity
        })
      }
    )
    builder.addCase(deleteProofComment.rejected, (state) => { })
  }
})

export const {
  togglePinMode,
  setPinMode,
  setStagedComment,
  resetStagedComment,
  setEditingComment,
  resetProoferActivitiesState
} = prooferActivitiesSlice.actions
export default prooferActivitiesSlice.reducer
