import { ComponentPreview } from 'components/ComponentPreview/ComponentPreview'
import React from 'react'
import { Pins } from './Pins/Pins'
import { DraggablePin } from './Pins/Pin'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { buildPinId } from 'state/labelproof/proofer/helpers'
import { setStagedComment } from 'state/labelproof/proofer/ProoferActivitiesSlice'
import { setDesignImageLoaded } from 'state/labelproof/proofer/ProoferSlice'

export interface PreviewProps {
  fileUrl?: string
  pins?: DraggablePin[]
}

export const PreviewContainer: React.FC<PreviewProps> = ({ fileUrl, pins }) => {
  const dispatch = useAppDispatch()
  const [currentScale, setCurrentScale] = React.useState<number>(1)
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0
  })

  const designImageLoaded = useAppSelector(
    (state) => state.proofer.designImageLoaded
  )
  const stagedComment = useAppSelector(
    (state) => state.prooferActivities.stagedComment
  )
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const pinMode = useAppSelector((state) => state.prooferActivities.pinMode)

  // Refs for precise positioning
  const containerRef = React.useRef<HTMLDivElement>(null)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const pinsContainerRef = React.useRef<HTMLDivElement>(null)

  const [pinsContainerStyle, setPinsContainerStyle] = React.useState({})

  React.useEffect(() => {
    if (!imgRef.current || !containerRef.current) return

    const updateImageDimensions = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0] // First entry is our image
      if (entry) {
        const { width, height } = entry.contentRect
        const img = imgRef.current

        if (img) {
          setImageDimensions({
            width: width,
            height: height,
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight
          })

          // Use the exact dimensions from the ResizeObserver entry
          // i.e. the actual dimensions of the image
          setPinsContainerStyle({
            position: 'absolute',
            width: `${width}px`,
            height: `${height}px`,
            top: '0px',
            left: '0px'
          })
        }
      }
    }

    // Create ResizeObserver
    const resizeObserver = new ResizeObserver(updateImageDimensions)

    // Start observing the image
    if (designImageLoaded && imgRef.current) {
      resizeObserver.observe(imgRef.current)
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect()
    }
  }, [designImageLoaded])

  const addPin = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!designImageLoaded || !pinMode || stagedComment.pin) {
      return
    }

    if (e.target !== pinsContainerRef.current) {
      return
    }

    const widthRatio = e.nativeEvent.offsetX / imageDimensions.width
    const heightRatio = e.nativeEvent.offsetY / imageDimensions.height

    dispatch(
      setStagedComment({
        pin: {
          id: buildPinId(widthRatio, heightRatio),
          widthRatio,
          heightRatio
        }
      })
    )
  }

  const updatePinPosition = (newX: number, newY: number, id: string) => {
    if (id !== stagedComment.pin?.id) {
      return
    }
    dispatch(
      setStagedComment({
        pin: {
          ...stagedComment.pin,
          widthRatio: newX / imageDimensions.width,
          heightRatio: newY / imageDimensions.height
        }
      })
    )
  }

  const handleTransformChange = (ref: any) => {
    setCurrentScale(ref.state.scale)
  }

  const handleImageLoad = () => {
    dispatch(setDesignImageLoaded(true))
  }

  const allDraggablePins = React.useMemo(() => {
    let draggablePins = []
    if (pins) {
      draggablePins.push(
        ...pins.map((pin) => ({
          id: pin.id,
          highlighted: pin.highlighted,
          commentId: pin.commentId,
          hidden: stagedComment.pin?.id === pin.id,
          x: pin.x * imageDimensions.width,
          y: pin.y * imageDimensions.height,
          draggable: pin.draggable,
          user: pin.user
        }))
      )
    }

    if (stagedComment.pin) {
      draggablePins.push({
        id: stagedComment.pin.id,
        x: stagedComment.pin.widthRatio * imageDimensions.width,
        y: stagedComment.pin.heightRatio * imageDimensions.height,
        highlighted: pinMode,
        hidden: false,
        draggable: true,
        user: {
          name: currentUser.firstName + ' ' + currentUser.lastName,
          avatarSrc: currentUser.imagePath
        }
      })
    } else {
      draggablePins = draggablePins.filter(
        (pin) => pin.commentId !== stagedComment.id
      )
    }

    return draggablePins
  }, [pins, stagedComment.pin, imageDimensions, pinMode])

  return (
    <ComponentPreview
      loading={false}
      loadingText="Loading design"
      onTransformChange={handleTransformChange}
      component={
        <div
          ref={containerRef}
          style={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
            height: '100%',
            backgroundColor: 'white'
          }}
          onMouseUp={addPin}
        >
          <img
            ref={imgRef}
            src={fileUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
            onLoad={handleImageLoad}
          />
          {designImageLoaded && (
            <div ref={pinsContainerRef} style={pinsContainerStyle}>
              <Pins
                pins={allDraggablePins}
                scale={currentScale}
                updatePosition={updatePinPosition}
              />
            </div>
          )}
        </div>
      }
    />
  )
}
