import {
  Collapse,
  Fade,
  Input,
  InputAdornment,
  InputProps,
  Slide
} from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'

import React from 'react'
import { BoxRow } from '../common'
import { Button } from '../Button/Button'

interface InputFieldInnerLabelProps
  extends Omit<InputProps, 'onChange' | 'value' | 'startAdornment' | 'size'> {
  label: string
  onChange?: (value: string) => void
  initialValue?: string
  debounceTime?: number
  maxLength?: number
  liveChanges?: boolean // If the changes are propagated while user is typing or upon confirmation.
}

export const InputFieldInnerLabel: React.FC<InputFieldInnerLabelProps> = ({
  label,
  onChange,
  initialValue,
  debounceTime = 0,
  maxLength,
  liveChanges = true,
  ...rest
}) => {
  const [value, setValue] = React.useState<string>()
  const [showButton, setShowButton] = React.useState<boolean>(false)
  const containerRef = React.useRef(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    /**
     * allow undefined or blank value
     * helps when using same input when dataset changes
     * ie: IngredientsViewer - selecting or creating ingredient
     */
    setValue(initialValue)
  }, [initialValue])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue)
      }
    },
    debounceTime
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (maxLength && inputValue.length > maxLength) return
    setValue(inputValue)
    if (liveChanges) {
      handleInputChangeDebounced(inputValue)
    }
  }

  const handleOnBlur = () => {
    setTimeout(() => {
      if (
        !buttonRef.current ||
        !buttonRef.current.contains(document.activeElement)
      ) {
        setShowButton(false)
        // Reset the value to initial one if live changes are off.
        if (!liveChanges) {
          setValue(initialValue)
        }
      }
    }, 100)
  }

  const handleOnFocus = () => {
    setShowButton(true)
  }

  const handleOnButtonClicked = () => {
    handleInputChangeDebounced(value || '0')
  }

  return (
    <BoxRow>
      <Input
        value={value || ''} // Bind input value to state
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        startAdornment={
          <InputAdornment position="start">{label}</InputAdornment>
        }
        {...rest}
        sx={{
          width: 'fit-content',
          lineHeight: '0.5em',
          padding: '6px 10px 6px 10px',
          background: '#FDFDFD' as const,
          border: '1px solid rgba(161, 171, 183, 0.3)' as const,
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)' as const,
          '& .MuiInputBase-input': {
            textAlign: 'right',
            padding: '0px'
          },
          borderBottomRightRadius:
            showButton && !liveChanges ? '0px' : undefined,
          borderTopRightRadius: showButton && !liveChanges ? '0px' : undefined,
          ...rest.sx
        }}
      />

      <div ref={containerRef} />

      {!liveChanges && showButton && (
        <Fade in={!liveChanges && showButton}>
          <Button
            ref={buttonRef}
            color="secondary"
            size="small"
            onClick={handleOnButtonClicked}
            onBlur={handleOnBlur}
            style={{
              height: '34px',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              boxShadow: 'none'
            }}
          >
            Update
          </Button>
        </Fade>
      )}
    </BoxRow>
  )
}
