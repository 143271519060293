import { ActiveIngredientDisplaySection } from 'models/FormulaIngredient'
import {
  DisplayAmountOption,
  DisplayDvOption,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient
} from 'models/FormulaLabel'
import { NutrientRequirement, NutrientType } from 'models/Nutrient'
import {
  SupplementFactsIngredient,
  SupplementFactsNutrient
} from 'models/SupplementFacts'
import React from 'react'
import { SupplementFactsLabelVertical } from './SupplementFactsVertical/SupplementFactsLabelVertical'

export interface SupplementFactsLabelProps {
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: string
  servingWeightOverride?: string
  servingsPerContainer: string
  type: FormulaNutritionFactLabelType
  ageGroup: FormulaNutritionFactLabelAgeGroup
  nutrients: SupplementFactsNutrient[]
  activeIngredients?: SupplementFactsIngredient[]
  selectedNutrients: NutrientType[]
  showProteinPercentage: boolean
}
export interface SupplementFactsProps {
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: string
  servingsPerContainer: string
  nutrients: SupplementFactsNutrient[]
  activeIngredients: SupplementFactsActiveIngredient[]
}

export interface SupplementFactsActiveIngredient {
  name: string
  amount: string
}

export const SupplementFactsLabel: React.FC<SupplementFactsLabelProps> = ({
  servingSize,
  servingWeight,
  servingWeightOverride,
  servingsPerContainer,
  nutrients,
  activeIngredients = [],
  selectedNutrients,
  showProteinPercentage
}) => {
  const mapDisplayNameOptions = (
    displayNameOptions: string[] | FormulaNutritionFactLabelLangSpecificFields
  ): FormulaNutritionFactLabelLangSpecificFields => {
    if (!Array.isArray(displayNameOptions)) {
      return displayNameOptions
    }

    return Object.values(FormulaNutritionFactLanguage).reduce(
      (acc, language, index) => {
        if (displayNameOptions[index]) {
          acc[language] = displayNameOptions[index]
        }
        return acc
      },
      {} as FormulaNutritionFactLabelLangSpecificFields
    )
  }

  const isNutrientNameOverrideAvailable = (
    nutrient: FormulaNutritionFactNutrient
  ): boolean => {
    return nutrient.overrideValues?.nutrientDisplayName
      ? Object.keys(nutrient.overrideValues?.nutrientDisplayName).length > 0
        ? true
        : false
      : false
  }

  const getNutrientDisplayNameOptions = (
    nutrient: FormulaNutritionFactNutrient
  ): FormulaNutritionFactLabelLangSpecificFields => {
    const originalNames = mapDisplayNameOptions(nutrient.displayNameOptions)

    if (isNutrientNameOverrideAvailable(nutrient)) {
      const overrides = nutrient.overrideValues
        ?.nutrientDisplayName as FormulaNutritionFactLabelLangSpecificFields

      // Combine overrides with original names, preferring overrides
      return Object.keys(originalNames).reduce((result, language) => {
        const lang = language as FormulaNutritionFactLanguage
        result[lang] = overrides[lang] || originalNames[lang]
        return result
      }, {} as FormulaNutritionFactLabelLangSpecificFields)
    }

    // Return original names if no overrides are available
    return originalNames
  }

  const getDisplayDvOptions = (
    nutrient: FormulaNutritionFactNutrient,
    showProteinDv: boolean
  ): DisplayDvOption[] => {
    if (nutrient.nutrient.type === NutrientType.PROTEIN && !showProteinDv) {
      return [] as DisplayDvOption[]
    }
    const dvOverride = nutrient.overrideValues?.dv
    const dv = dvOverride || nutrient.displayDvOptions[0]?.amount || ''
    const percentageSymbol = dv === '' ? '' : '%*'

    return [{ amount: `${dv}${percentageSymbol}` }]
  }

  const getDisplayAmountOptions = (
    nutrient: FormulaNutritionFactNutrient
  ): DisplayAmountOption[] => {
    const amountOverride = nutrient.overrideValues?.amount
    const originalAmount = nutrient.displayAmountOptions[0]
    const unit =
      nutrient.nutrient.type === NutrientType.CALORIES
        ? ''
        : ' ' + nutrient.nutrient.unit
    return amountOverride
      ? [{ amount: amountOverride }]
      : [{ amount: `${originalAmount.amount}${unit}` }]
  }

  const getActiveIngredients = (
    ingredients: SupplementFactsIngredient[]
  ): SupplementFactsActiveIngredient[] => {
    return (
      ingredients?.filter(
        (i) =>
          i.active &&
          [
            ActiveIngredientDisplaySection.ACTIVE_INGREDIENTS,
            ActiveIngredientDisplaySection.BOTH
          ].includes(i.displaySection)
      ) || []
    ).map((ai) => {
      const unit = ai.overrideValue?.amount ? '' : ' ' + ai.measurement.unit
      const amount = ai.overrideValue?.amount || ai.amountInMeasurement
      return {
        name: ai.overrideValue?.name || ai.ingredientStatement || ai.name,
        amount: `${amount}${unit}`
      }
    })
  }

  const getServingWeight = (
    servingWeight: string,
    servingSizeOverride?: string
  ): string => {
    return servingSizeOverride
      ? servingSizeOverride
      : servingWeight.toString() + 'g'
  }

  const getNutrients = (
    nutrients: SupplementFactsNutrient[],
    showProteinPercentage: boolean
  ): SupplementFactsNutrient[] => {
    return nutrients
      .filter(
        (sn) =>
          (sn.nutrient.requirement === NutrientRequirement.OPTIONAL &&
            selectedNutrients?.includes(sn.nutrient.type)) ||
          sn.nutrient.requirement === NutrientRequirement.MANDATORY
      )
      .map((a) => {
        return {
          nutrient: a.nutrient,
          displayDvOptions: getDisplayDvOptions(a, showProteinPercentage),
          displayAmountOptions: getDisplayAmountOptions(a),
          displayNameOptions: getNutrientDisplayNameOptions(a),
          source: a.source
        }
      })
      .sort((a, b) => a.nutrient.rank - b.nutrient.rank)
  }

  const props: SupplementFactsProps = {
    servingsPerContainer,
    servingSize,
    servingWeight: getServingWeight(servingWeight, servingWeightOverride),
    nutrients: getNutrients(nutrients, showProteinPercentage),
    activeIngredients: getActiveIngredients(activeIngredients)
  }

  return <SupplementFactsLabelVertical {...props} />
}
