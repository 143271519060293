import { Box, Tooltip } from '@mui/material'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'
import { ReactComponent as Checkmark } from 'assets/Checkmark.svg'
import { ReactComponent as PlusMark } from 'assets/PlusMark.svg'

import React from 'react'
import { displayDate } from 'common/utils'

export enum ActionStatus {
  APPROVED = 'approved',
  ADDED = 'added'
}

export interface AvatarAction {
  status: ActionStatus
  date: string
}

export interface AvatarProps {
  id: string
  name: string
  imageUrl?: string
  action?: AvatarAction
  size?: number
}

export const Avatar: React.FC<AvatarProps> = ({
  id,
  name,
  imageUrl,
  action,
  size = 30
}) => {
  const [toolTipTitle, setToolTipTitle] = React.useState<string>('')

  const handleToolTipOpen = () => {
    if (!action || !action.date) {
      setToolTipTitle(name)
      return
    }
    const actionText = action.status === 'approved' ? 'approved' : 'added'
    setToolTipTitle(
      `${name} ${actionText} item ${displayDate(action.date, true)}`
    )
  }

  return (
    <Box key={`avatar-${id}`} sx={{ display: 'inline-block' }}>
      <Tooltip
        title={toolTipTitle}
        onOpen={handleToolTipOpen}
        arrow
        enterNextDelay={500}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -5]
                }
              }
            ]
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <AvatarInitials
            variant="circle"
            name={name}
            avatarSrc={imageUrl}
            size={size}
          />
          {action && (
            <Box sx={{ position: 'absolute', right: '-6px', bottom: '-8px' }}>
              {action.status === ActionStatus.APPROVED ? (
                <Checkmark width={'15px'} height={'15px'} />
              ) : (
                <PlusMark width={'15px'} height={'15px'} />
              )}
            </Box>
          )}
        </Box>
      </Tooltip>
    </Box>
  )
}
