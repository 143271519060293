import { SupplementFactsActiveIngredient } from 'components/NutritionLabel/SupplementFactsLabel'
import { ItemTableContent } from './ItemTableContent'

export const ActiveIngredientsTable: React.FC<{
  activeIngredients: SupplementFactsActiveIngredient[]
}> = ({ activeIngredients = [] }) => {
  return (
    <>
      <ItemTableContent
        tableItemRowsProps={activeIngredients.map((ai) => {
          return {
            itemRowValues: {
              name: ai.name,
              amount: ai.amount,
              dvPercentage: '†'
            }
          }
        })}
      />
    </>
  )
}
