import { Typography } from '@mui/material'
import { Select } from 'components/Select/Select'
import { BoxColumnBasic, BoxRow, SelectOption } from 'components/common'
import { ActiveFormulaIngredientSectionDisplayPreview } from 'models/FormulaLabel'
import React from 'react'
import { EditorSection } from '../../../common/EditorSection'
import { ActiveIngredientDisplaySection } from 'models/FormulaIngredient'

interface ActiveIngredientsDisplayProps {
  activeIngredientsListing: ActiveFormulaIngredientSectionDisplayPreview[]
  displaySectionOptions: SelectOption<ActiveIngredientDisplaySection>[]
  onDisplaySectionChange: (id: string, displaySection: ActiveIngredientDisplaySection) => void
}

export const ActiveIngredientsDisplay: React.FC<ActiveIngredientsDisplayProps> = ({
  activeIngredientsListing,
  displaySectionOptions,
  onDisplaySectionChange
}) => {
  if (activeIngredientsListing.length === 0) {
    return null
  }

  return (
    <EditorSection title="Active Ingredients">
      <BoxColumnBasic sx={{ gap: '10px' }}>
        {activeIngredientsListing.map((activeIngredient) => (
          <BoxRow
            key={activeIngredient.id}
            sx={{ gap: '5px', justifyContent: 'space-between' }}
          >
            <Typography>{activeIngredient.name}</Typography>
            <Select
              options={displaySectionOptions}
              value={activeIngredient.displaySection}
              onChange={(displaySection) => onDisplaySectionChange(activeIngredient.id, displaySection)}
            />
          </BoxRow>
        ))}
      </BoxColumnBasic>
    </EditorSection>
  )
}
