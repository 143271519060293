import React from 'react'
import { pluralize } from 'common/utils'
import { NutritionFactsProps } from '../NutritionLabel'
import { NutritionFactsRule } from '../components/NutritionFactsRule'
import {
  Container,
  FlexContainerColumnSpaced
} from '../components/NutritionLabelStyles'
import { NutritionLabelText } from '../components/NutritionLabelText'
import {
  AmountPerServingContainer,
  FooterNoteContainer,
  NutrientsBreakdownContainer,
  NutritionFactDetailContainer,
  NutritionLabelTabularBox
} from './NutritionLabelTabularStyles'
import { TopNutrientsTable } from './components/TopNutrientsTable'
import { BottomNutrientsText } from './components/BottomNutrientsTable'
import { FormulaNutritionFactLabelAgeGroup } from 'models/FormulaLabel'
import { extractServingPerContainerNumber } from '../helpers'

export const NutritionLabelTabular: React.FC<NutritionFactsProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories,
  ageGroup
}) => {
  const servingsPerContainerNumber =
    extractServingPerContainerNumber(servingsPerContainer) || 0
  return (
    <Container>
      <NutritionLabelTabularBox>
        <NutritionFactDetailContainer>
          <NutritionLabelText bold points={20} wrapText>
            Nutrition Facts
          </NutritionLabelText>
          <NutritionFactsRule type="hairline" margin={2} />
          <FlexContainerColumnSpaced>
            <NutritionLabelText points={10}>
              {servingsPerContainer}{' '}
              {pluralize(servingsPerContainerNumber, 'serving')} per container
            </NutritionLabelText>
            <NutritionLabelText bold points={9}>
              Serving size
            </NutritionLabelText>
            {Object.keys(servingSize).map((language) => (
              <NutritionLabelText bold points={9} key={`ss-${language}`}>
                {servingSize[language as keyof typeof servingSize]} (
                {servingWeight})
              </NutritionLabelText>
            ))}
          </FlexContainerColumnSpaced>
          <NutritionFactsRule type="hairline" margin={2} />
          <AmountPerServingContainer>
            <FlexContainerColumnSpaced>
              <NutritionLabelText bold points={12}>
                Calories
              </NutritionLabelText>
              <NutritionLabelText bold points={8}>
                per serving
              </NutritionLabelText>
            </FlexContainerColumnSpaced>
            <NutritionLabelText bold points={22}>
              {calories}
            </NutritionLabelText>
          </AmountPerServingContainer>
        </NutritionFactDetailContainer>
        <NutrientsBreakdownContainer>
          <TopNutrientsTable
            topNutrients={topNutrients}
            showDvPercentageStar={
              ageGroup !== FormulaNutritionFactLabelAgeGroup.INFANT
            }
          />
          <BottomNutrientsText bottomNutrients={bottomNutrients} />
        </NutrientsBreakdownContainer>
        {ageGroup != FormulaNutritionFactLabelAgeGroup.INFANT && (
          <FooterNoteContainer>
            <NutritionLabelText points={7} wrapText={true}>
              *The % Daily Value (DV) tells you how much a nutrient in a serving
              of food contributes to a daily diet.{' '}
              {ageGroup ===
              FormulaNutritionFactLabelAgeGroup.CHILDREN_LESS_THAN_FOUR
                ? '1,000'
                : '2,000'}{' '}
              calories a day is used for general nutrition advice.
            </NutritionLabelText>
          </FooterNoteContainer>
        )}
      </NutritionLabelTabularBox>
    </Container>
  )
}
