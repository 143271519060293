import { SupplementFactsNutrient } from 'models/SupplementFacts'
import { NutritionFactsRule } from '../../components/NutritionFactsRule'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { PercentDailyValueContainer } from '../SupplementLabelVerticalStyles'
import { ItemTableContent } from './ItemTableContent'
import {
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import { NutrientType } from 'models/Nutrient'

export const NutrientsTable: React.FC<{
  nutrients: SupplementFactsNutrient[]
}> = ({ nutrients = [] }) => (
  <>
    <PercentDailyValueContainer>
      <NutritionLabelText bold points={6} additionalLineHeight={2}>
        Amount Per Serving
      </NutritionLabelText>
      <NutritionLabelText bold points={6} additionalLineHeight={2}>
        % Daily Value
      </NutritionLabelText>
    </PercentDailyValueContainer>
    <NutritionFactsRule type="normal" margin={2} />
    {nutrients.length > 0 && (
      <ItemTableContent
        key={`nutrients-${0}`}
        tableItemRowsProps={nutrients.map((sfn) => {
          const displayNames =
            sfn.displayNameOptions as FormulaNutritionFactLabelLangSpecificFields
          return {
            nutrientType: sfn.nutrient.type,
            itemRowValues: {
              name: displayNames[FormulaNutritionFactLanguage.ENGLISH] || '',
              amount: sfn.displayAmountOptions[0]?.amount,
              dvPercentage: sfn.displayDvOptions[0]?.amount || ''
            }
          }
        })}
      />
    )}
  </>
)
