import { DotStatus } from 'components/DotStatus/DotStatus'
import { TextField, TextFieldProps } from 'components/InputFields/TextField'
import React from 'react'
import { MainColors } from 'styles/colors'

export interface OverrideTextFieldProps extends TextFieldProps {
  originalValue?: string
}

/**
 * Shows a text field that can be overridden by the user.
 * If focused, user would start with original value.
 */
export const OverrideTextField: React.FC<OverrideTextFieldProps> = ({
  originalValue,
  ...props
}) => {
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    props.value
  )

  React.useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const handleFocus = () => {
    if (props.value === '') {
      setCurrentValue(originalValue)
    }
  }

  const handleBlur = () => {
    if (props.value === '') {
      setCurrentValue('')
    }
  }

  return (
    <TextField
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={currentValue}
      placeholder={originalValue}
      endAdornment={
        <>
          {props.endAdornment}
          {props.value ? (
            <DotStatus color={MainColors.warning} sx={{ marginLeft: '5px' }} />
          ) : undefined}
        </>
      }
    />
  )
}
