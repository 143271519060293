import { AllergenDisplayNameLanguage } from 'models/Allergen'
import {
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { getAllergens } from 'state/allergens/AllergensSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getAvailableRegulations,
  getNutritionFactLabelPreview,
  getSupplementFactsPreview
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { EditorPreview } from './EditorPreview'

export const EditorPreviewContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const loadingNFPLabel = useAppSelector(
    (state) => state.nutritionFactLabels.loadingNFPLabel
  )

  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const originalIngredientStatement = useAppSelector(
    (state) => state.ingredientStatements.ingredientStatementPreview
  )
  const selectedAllergens = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.allergens
  )
  const ingredientStatementPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ingredientStatement
  )
  const allergenStatementPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.allergenStatement
  )
  const allergenStatementPrefix = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.allergenStatementPrefix
  )
  const suggestedAllergenStatementPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.suggestedAllergenStatement
  )
  const description = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.description
  )
  const allAllergens = useAppSelector((state) => state.allergens.allergens)
  const regulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )
  const availableRegulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )
  const nfpType = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.type
  )
  const pdcaas = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.pdcaas
  )
  const applyPdcaas = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.applyPdcaas
  )
  const dvBasedOnRounded = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.dvBasedOnRounded
  )
  const ageGroup = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ageGroup
  )
  const containerWeight = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.containerWeight
  )
  const formulaRegulationSettingsEdits = useAppSelector(
    (state) => state.formulator.formulaRegulationsSettingsEdits
  )
  const formulaIsSupplementEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )
  const formulaIngredientsStatementFormatPreview = useAppSelector(
    (state) =>
      state.ingredientStatements.formulaIngredientsStatementFormatPreview
  )

  React.useEffect(() => {
    void dispatch(getAllergens(regulationId))
  }, [selectedAllergens, regulationId])

  React.useEffect(() => {
    void dispatch(getAvailableRegulations())
  }, [])

  React.useEffect(() => {
    if (regulationId) {
      if (!formulaIsSupplementEdits) {
        void dispatch(
          getNutritionFactLabelPreview({
            companyId: currentCompany.id,
            formulaId: formulaId,
            ageGroup: ageGroup,
            containerWeight:
              nutritionFactLabelEdits.nutritionFactLabelPreview.type ===
              FormulaNutritionFactLabelType.DUAL
                ? containerWeight
                : undefined,
            regulationId: regulationId,
            showProteinPercentage:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .showProteinPercentage,
            type: nfpType,
            pdcaas: pdcaas,
            applyPdcaas: applyPdcaas,
            dvBasedOnRounded: dvBasedOnRounded,
            statementCase: formulaRegulationSettingsEdits.find(
              (setting) => setting.regulationId === regulationId
            )?.statementCase,
            allergenTypes: selectedAllergens,
            formulaIngredientsStatementFormatPreview,
            servingSize:
              nutritionFactLabelEdits.nutritionFactLabelPreview.servingSize,
            description:
              nutritionFactLabelEdits.nutritionFactLabelPreview.description,
            allergenStatement:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .allergenStatement,
            ingredientStatement:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .ingredientStatement,
            optionalNutrientsTypes:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .optionalNutrientsType
          })
        )
      } else {
        void dispatch(
          getSupplementFactsPreview({
            companyId: currentCompany.id,
            formulaId: formulaId,
            showProteinPercentage:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .showProteinPercentage,
            pdcaas: pdcaas,
            applyPdcaas: applyPdcaas,
            dvBasedOnRounded: dvBasedOnRounded,
            statementCase: formulaRegulationSettingsEdits.find(
              (setting) => setting.regulationId === regulationId
            )?.statementCase,
            allergenTypes: selectedAllergens,
            servingSize:
              nutritionFactLabelEdits.nutritionFactLabelPreview.servingSize,
            description:
              nutritionFactLabelEdits.nutritionFactLabelPreview.description,
            allergenStatement:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .allergenStatement,
            ingredientStatement:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .ingredientStatement,
            optionalNutrientsTypes:
              nutritionFactLabelEdits.nutritionFactLabelPreview
                .optionalNutrientsType,
            activeFormulaIngredientsSectionDisplay: nutritionFactLabelEdits.activeIngredientsSectionDisplay
          })
        )
      }
    }
  }, [
    currentCompany.id,
    formulaId,
    regulationId,
    nfpType,
    pdcaas,
    applyPdcaas,
    dvBasedOnRounded,
    ageGroup,
    containerWeight,
    formulaRegulationSettingsEdits,
    selectedAllergens,
    formulaIsSupplementEdits,
    formulaIngredientsStatementFormatPreview,
    nutritionFactLabelEdits.activeIngredientsSectionDisplay
  ])

  const allergens = React.useMemo(() => {
    const getLanguageList = (
      language: AllergenDisplayNameLanguage
    ): string[] => {
      return selectedAllergens
        .flatMap((selectedAllergen) => {
          const allergen = allAllergens.find(
            (allergen) => allergen.type === selectedAllergen
          )
          if (allergen?.displayNames && allergen.displayNames[language]) {
            return allergen.displayNames[language]
          }
          return []
        })
        .filter((name): name is string => name !== undefined)
    }
    return {
      [AllergenDisplayNameLanguage.ENGLISH]: getLanguageList(
        AllergenDisplayNameLanguage.ENGLISH
      ),
      [AllergenDisplayNameLanguage.FRENCH]: getLanguageList(
        AllergenDisplayNameLanguage.FRENCH
      ),
      [AllergenDisplayNameLanguage.SPANISH]: getLanguageList(
        AllergenDisplayNameLanguage.SPANISH
      )
    }
  }, [selectedAllergens, allAllergens])

  const suggestedIngredientsStatement = React.useMemo(() => {
    return (
      originalIngredientStatement?.suggestedIngredientStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [originalIngredientStatement])

  const regulationName = React.useMemo(() => {
    return (
      (availableRegulations.find((r) => r.id === regulationId)
        ?.name as RegulationName) || RegulationName.FDA
    )
  }, [regulationId, availableRegulations])

  return (
    <EditorPreview
      factsLabel={{
        ingredientStatement: {
          statement: ingredientStatementPreview,
          suggestedStatement: suggestedIngredientsStatement,
          prefix: originalIngredientStatement?.ingredientStatementPrefix || {
            [FormulaNutritionFactLanguage.ENGLISH]: 'Ingredients: ',
            [FormulaNutritionFactLanguage.FRENCH]: 'Ingrédients: ',
            [FormulaNutritionFactLanguage.SPANISH]: 'Ingredientes: '
          }
        },
        allergenStatement: {
          allergens: allergens,
          statementsOverride: allergenStatementPreview,
          suggestedAllergenStatement: suggestedAllergenStatementPreview,
          allergenStatementPrefix: allergenStatementPrefix || {
            [FormulaNutritionFactLanguage.ENGLISH]: 'Contains: ',
            [FormulaNutritionFactLanguage.FRENCH]: 'Contient: ',
            [FormulaNutritionFactLanguage.SPANISH]: 'Contiene: '
          }
        },
        labelDescription: {
          description: description || {
            [FormulaNutritionFactLanguage.ENGLISH]: ''
          }
        },
        facts: {
          ...nutritionFactLabelEdits.nutritionFactLabelPreview
        },
        regulationName: regulationName
      }}
      loading={loadingNFPLabel}
    />
  )
}
