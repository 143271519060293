import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { capitalize } from 'common/utils'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import React from 'react'
import { EditorSection } from '../../../common/EditorSection'
import {
  NutrientRow,
  NutrientRowProps,
  NutrientRowSkeleton
} from './components/NutrientRow'
import { DynamicColgroup } from './DynamicColgroup'

const Separator = styled(Box)`
  height: 8px;
  background: var(--Beige, #f6f5f4);
`

const getPlaceholderRowGroups = (colSpan: number) => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <React.Fragment key={index}>
          {Array.from({ length: 3 }).map((_, subIndex) => (
            <NutrientRowSkeleton key={subIndex} colSpan={colSpan} />
          ))}
          <TableRow>
            <TableCell colSpan={colSpan} sx={{ padding: '8px 0' }}>
              <Separator />
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </>
  )
}

export interface OverridesProps {
  availableLanguagesInOverrides: FormulaNutritionFactLanguage[]
  nutrientTableRowGroups?: NutrientTableRowGroup[]
  showPerContainerFields?: boolean
  hideUnits?: boolean
  multiline?: boolean
  loading?: boolean
}
export interface NutrientTableRowGroup {
  nutrientRows: NutrientRowProps[]
}

export const NutrientsOverrides: React.FC<OverridesProps> = ({
  availableLanguagesInOverrides,
  nutrientTableRowGroups = [],
  hideUnits = false,
  multiline = false,
  loading = false
}) => {
  const showPerContainerFields = React.useMemo(() => {
    return nutrientTableRowGroups.some((nrg) => {
      return nrg.nutrientRows.some((nr) => {
        return nr.originals.perContainer !== undefined
      })
    })
  }, [nutrientTableRowGroups])

  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  return (
    <EditorSection title="Nutrients">
      <Box
        ref={tableContainerRef}
        sx={{ overflowX: 'auto', height: '100%', width: '100%' }}
      >
        {(loading || nutrientTableRowGroups.length > 0) && (
          <TableContainer sx={{ padding: '8px 0px', width: '100%' }}>
            <Table style={{ tableLayout: 'fixed' }}>
              <DynamicColgroup
                availableLanguagesInOverrides={availableLanguagesInOverrides}
                showPerContainerFields={showPerContainerFields}
                containerRef={tableContainerRef}
              />
              <TableHead>
                <TableRow key="nutrient-table-header">
                  <TableCell
                    align="left"
                    sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 2,
                      background:
                        'linear-gradient(90deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)'
                    }}
                  >
                    <Typography color="secondary" variant="body1">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      DV %
                    </Typography>
                  </TableCell>
                  {availableLanguagesInOverrides.length > 0 &&
                    availableLanguagesInOverrides.map((lang) => (
                      <TableCell key={`lang-${lang}`} align="left">
                        <Typography color="secondary" variant="body1">
                          {lang === FormulaNutritionFactLanguage.ENGLISH
                            ? 'Name'
                            : `Name (${capitalize(lang)})`}
                        </Typography>
                      </TableCell>
                    ))}
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      DV %
                    </Typography>
                  </TableCell>
                  {showPerContainerFields && (
                    <>
                      <TableCell align="left">
                        <Typography color="secondary" variant="body1">
                          Amount (Container)
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography color="secondary" variant="body1">
                          DV % (Container)
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  nutrientTableRowGroups.length > 0 &&
                  nutrientTableRowGroups.map((nrg, index) => (
                    <React.Fragment key={`nrg-frag-${index}`}>
                      {nrg.nutrientRows.map((nr, i) => (
                        <NutrientRow
                          key={`nr-${index}-${i}`}
                          id={nr.id}
                          originals={nr.originals}
                          level={nr.level}
                          overrides={nr.overrides}
                          disabledName={nr.disabledName}
                          availableLanguagesInOverrides={
                            availableLanguagesInOverrides
                          }
                          hideUnits={hideUnits}
                          multiline={multiline}
                        />
                      ))}
                      {index < nutrientTableRowGroups.length - 1 && (
                        <TableRow key={`nrg-sep-${index}`}>
                          <TableCell
                            colSpan={
                              availableLanguagesInOverrides.length +
                              5 +
                              (showPerContainerFields ? 2 : 0)
                            }
                            sx={{ padding: '8px 0' }}
                          >
                            <Separator />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                {loading &&
                  getPlaceholderRowGroups(
                    5 +
                      availableLanguagesInOverrides.length +
                      (showPerContainerFields ? 2 : 0)
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!loading && nutrientTableRowGroups.length === 0 && (
          <Typography variant="subtitle2">
            <i>No mandatory or voluntary nutrients to override.</i>
          </Typography>
        )}
      </Box>
    </EditorSection>
  )
}
