import { LabelProofActivityApiResponse, LabelProofCommentApiResponse } from "services/apis/label_proofs/LabelProofApiResponse";
import { CreateProofCommentRequestData, UpdateProofCommentRequestData } from "state/labelproof/proofer/ProoferRequest";
import { Activity, StagedComment, StagedCommentReplyTo, isComment } from "state/labelproof/proofer/ProoferActivitiesSlice";
import { addPinToText, getMentionedUsersMap, getPinsMap } from "./helpers";
import { ProofLogProps } from "./components/ProofLog/ProofLog";
import { ProofCommentProps } from "./components/ProofComment/ProofComment";


export const mapCommentReplyToStagedCommentReply = (replyTo?: LabelProofCommentApiResponse): StagedCommentReplyTo | undefined => {
    if (!replyTo) {
        return undefined
    }
    return {
        id: replyTo.id,
        comment: addPinToText(replyTo.comment, replyTo.pin),
        mentionsMap: getMentionedUsersMap(replyTo.mentions),
        pinMap: getPinsMap([replyTo.pin]),
        pinId: replyTo.pin?.id
    }
}

export const mapCommentToStagedComment = (comment: LabelProofCommentApiResponse): StagedComment => {
    return {
        id: comment.id,
        comment: comment.comment,
        replyTo: mapCommentReplyToStagedCommentReply(comment.replyTo),
        pin: comment.pin,
        mentionedUserIds: comment.mentions.map(
            (mention) => mention.mentionedUser.id
        )
    }
}

export const mapStagedCommentToCreateProofCommentRequestData = (stagedComment: StagedComment): CreateProofCommentRequestData => {
    return {
        comment: stagedComment.comment,
        reply_to: stagedComment.replyTo?.id,
        pin: stagedComment.pin && {
            widthRatio: stagedComment.pin.widthRatio,
            heightRatio: stagedComment.pin.heightRatio
        },
        mentionedUserIds: stagedComment.mentionedUserIds
    }
}

export const mapStagedCommentToUpdateProofCommentRequestData = (stagedComment: StagedComment): UpdateProofCommentRequestData => {
    return {
        comment: stagedComment.comment,
        pin: stagedComment.pin && {
            widthRatio: stagedComment.pin.widthRatio,
            heightRatio: stagedComment.pin.heightRatio
        },
        deleteOldPin: stagedComment.pin === undefined,
        mentionedUserIds: stagedComment.mentionedUserIds
    }
}

const mapCommentToProps = (
    comment: LabelProofCommentApiResponse,
    currentUserId: string,
    onDeleteClick: (id: string) => void,
    onEditClick: (id: string) => void,
    onReplyClick: (id: string) => void,
    onPinClick: (id: string) => void
): ProofCommentProps => {
    return {
        id: comment.id,
        text: addPinToText(comment.comment, comment.pin),
        createdBy: comment.createdBy && {
            name: comment.createdBy.firstName + ' ' + comment.createdBy.lastName,
            isOwner: comment.createdBy.id === currentUserId,
            avatarSrc: comment.createdBy.imagePath
        },
        repliedComment: comment.replyTo && {
            id: comment.replyTo.id,
            text: addPinToText(comment.replyTo.comment, comment.replyTo.pin),
            createdByName: comment.replyTo.createdBy && comment.replyTo.createdBy.firstName + ' ' + comment.replyTo.createdBy.lastName,
            deleted: comment.replyTo.isDeleted,
        },
        timestamp: comment.createdAt,
        edited: !!comment.lastEditedAt,
        deleted: comment.isDeleted,
        mentionedUsersMap: getMentionedUsersMap([
            ...comment.mentions,
            ...(comment.replyTo?.mentions || [])
        ]),
        pinsMap: getPinsMap([comment.pin, comment.replyTo?.pin]),
        onDeleteClick,
        onEditClick,
        onReplyClick,
        onPinClick
    }
}

export const mapLogToProps = (
    log: LabelProofActivityApiResponse
): ProofLogProps => {
    return {
        occurredAt: log.occurredAt,
        user: log.linkedUser && {
            name: log.linkedUser.firstName + ' ' + log.linkedUser.lastName,
            avatarSrc: log.linkedUser.imagePath
        },
        details: log.details
    }
}

export const mapActivitiesToCommentsAndLogsProps = (
    activities: Activity[],
    currentUserId: string,
    onDeleteClick: (id: string) => void,
    onEditClick: (id: string) => void,
    onReplyClick: (id: string) => void,
    onPinClick: (id: string) => void
): (ProofCommentProps | ProofLogProps)[] => {
    return activities.map((activity) => isComment(activity)
        ? mapCommentToProps(
            activity,
            currentUserId,
            onDeleteClick,
            onEditClick,
            onReplyClick,
            onPinClick
        )
        : mapLogToProps(activity)
    )
}

