import { FormulaNutrient } from 'models/Formula'
import {
  FormulaNFPOverride,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactNutrient
} from 'models/FormulaLabel'
import { NutrientType } from 'models/Nutrient'
import { NutrientRowProps } from './components/NutrientRow'
import { NutrientTableRowGroup } from './NutrientsOverides'

const toTableNutrientsRow = (
  fn: FormulaNutrient,
  level = 0,
  overridesMap: Record<string, FormulaNFPOverride>,
  onOverrideChange: (
    id: string,
    name?: FormulaNutritionFactLabelLangSpecificFields,
    amount?: string,
    dv?: string,
    perContainerAmount?: string,
    perContainerDvPercentage?: string
  ) => void,
  perServingFnfn: Record<string, FormulaNutritionFactNutrient>,
  perContainerFnfn?: Record<string, FormulaNutritionFactNutrient>,
  uneditableNames?: Set<NutrientType>
): NutrientRowProps => {
  const perContainer = perContainerFnfn
    ? {
        displayAmount:
          perContainerFnfn[fn.nutrient.id]?.displayAmountOptions[0]?.amount,
        displayDvPercentage:
          perContainerFnfn[fn.nutrient.id]?.displayDvOptions[0]?.amount
      }
    : undefined

  return {
    id: fn.nutrient.id,
    originals: {
      unit: fn.nutrient.unit,
      displayName:
        (perServingFnfn[fn.nutrient.id]?.displayNameOptions as string[]) ?? [],
      displayAmount:
        perServingFnfn[fn.nutrient.id]?.displayAmountOptions[0]?.amount,
      displayDvPercentage:
        perServingFnfn[fn.nutrient.id]?.displayDvOptions[0]?.amount,
      perContainer
    },
    level: level,
    overrides: {
      name: overridesMap[fn.nutrient.id]?.nutrientDisplayName,
      amount: overridesMap[fn.nutrient.id]?.amount,
      dvPercentage: overridesMap[fn.nutrient.id]?.dv,
      perContainerAmount: overridesMap[fn.nutrient.id]?.perContainerAmount,
      perContainerDvPercentage: overridesMap[fn.nutrient.id]?.perContainerDv,
      onChange: onOverrideChange
    },
    disabledName: uneditableNames?.has(fn.nutrient.type)
  }
}

const toTableNutrientRows = (
  fn: FormulaNutrient,
  level = 0,
  overridesMap: Record<string, FormulaNFPOverride>,
  onOverrideChange: (
    id: string,
    name?: FormulaNutritionFactLabelLangSpecificFields,
    amount?: string,
    dv?: string,
    perContainerAmount?: string,
    perContainerDvPercentage?: string
  ) => void,
  perServingFnfn: Record<string, FormulaNutritionFactNutrient>,
  perContainerFnfn?: Record<string, FormulaNutritionFactNutrient>,
  visibleNutrients?: Set<NutrientType>,
  uneditableNames?: Set<NutrientType>
): NutrientRowProps[] => {
  const children = fn.children
    .flatMap((c) =>
      toTableNutrientRows(
        c,
        level + 1,
        overridesMap,
        onOverrideChange,
        perServingFnfn,
        perContainerFnfn,
        visibleNutrients,
        uneditableNames
      )
    )
    .filter((child): child is NutrientRowProps => child !== undefined)

  if (!visibleNutrients?.has(fn.nutrient.type)) {
    return children
  }

  const row = toTableNutrientsRow(
    fn,
    level,
    overridesMap,
    onOverrideChange,
    perServingFnfn,
    perContainerFnfn,
    uneditableNames
  )
  return [row, ...children]
}

export const toTableNutrientsRowGroup = (
  formulaNutrientGroups: FormulaNutrient[][],
  overridesMap: Record<string, FormulaNFPOverride>,
  onOverrideChange: (
    id: string,
    name?: FormulaNutritionFactLabelLangSpecificFields,
    amount?: string,
    dv?: string,
    perContainerAmount?: string,
    perContainerDvPercentage?: string
  ) => void,
  nutritionFactsPreviewNutrients: FormulaNutritionFactNutrient[],
  nutritionFactsPreviewPerContainerNutrients?: FormulaNutritionFactNutrient[],
  visibileNutrients?: Set<NutrientType>,
  uneditableNames?: Set<NutrientType>
): NutrientTableRowGroup[] => {
  // Create a dictionary that maps nutrient id to the perserving and percontainer.
  const perContainerFnfn = nutritionFactsPreviewPerContainerNutrients?.reduce(
    (acc, n) => {
      acc[n.nutrient.id] = n
      return acc
    },
    {} as Record<string, FormulaNutritionFactNutrient>
  )
  const perServingFnfn = nutritionFactsPreviewNutrients.reduce((acc, n) => {
    acc[n.nutrient.id] = n
    return acc
  }, {} as Record<string, FormulaNutritionFactNutrient>)

  return formulaNutrientGroups
    .map((fng) => ({
      nutrientRows: fng.flatMap((fn) =>
        toTableNutrientRows(
          fn,
          0,
          overridesMap,
          onOverrideChange,
          perServingFnfn,
          perContainerFnfn,
          visibileNutrients,
          uneditableNames
        )
      )
    }))
    .filter((group) => group.nutrientRows.length > 0)
}
