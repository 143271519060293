import { MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { SelectOption } from '../SelectOptionsLabel/SelectOptionsLabel'
import { FormControlStyled } from '../common'
import {
  ChipCount,
  FilterNameBox,
  FilterNameTypography,
  FilterSearchTextField
} from './Filter/EntrTableFilterDropDownStyles'

interface EntrTableFilterDropDownProps {
  name?: string
  options: (string | SelectOption)[]
  onUpdateSelectionCallback?: (values: string[]) => void
  paramName?: string
}

export const EntrTableFilterDropDown = ({
  name = '',
  options = [],
  onUpdateSelectionCallback,
  paramName = ''
}: EntrTableFilterDropDownProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultValue = searchParams.get(paramName)?.split(',') || []
  const [selected, setSelected] = React.useState<Set<string>>(
    new Set(defaultValue)
  )
  const [filteredOptions, setFilteredOptions] =
    React.useState<(string | SelectOption)[]>(options)
  const [searchText, setSearchText] = React.useState<string>('')

  const handleChange = (newSelected: string[]) => {
    const updatedSet = new Set(selected)
    newSelected.forEach((term) => {
      if (term === name) {
        return
      }
      if (updatedSet.has(term)) {
        updatedSet.delete(term)
      } else if (term) {
        updatedSet.add(term)
      }
    })
    setSelected(updatedSet)
  }

  const getRenderValue = () => {
    return (
      <FilterNameBox>
        <FilterNameTypography>{name}</FilterNameTypography>
        {selected.size > 0 && <ChipCount size="small" label={selected.size} />}
      </FilterNameBox>
    )
  }

  React.useEffect(() => {
    const newFilteredOptions = options.filter((option) => {
      const label = typeof option === 'string' ? option : option.label
      return label.toLowerCase().includes(searchText)
    })

    newFilteredOptions.sort((a, b) => {
      const aValue = typeof a === 'string' ? a : (a.value as string)
      const bValue = typeof b === 'string' ? b : (b.value as string)
      return Number(selected.has(bValue)) - Number(selected.has(aValue))
    })
    setFilteredOptions(newFilteredOptions)
  }, [options, searchText, selected])

  React.useEffect(() => {
    // If selected gets updated, trigger the onUpdate.
    if (onUpdateSelectionCallback) {
      onUpdateSelectionCallback([...selected])
    }

    if (paramName) {
      // Update the url query accordingly.
      if (selected.size) {
        const terms = ([...selected] as string[]).map((term) => {
          const option = options.find((o) => {
            if (typeof o === 'string') {
              return o === term
            } else {
              return o.value === term
            }
          })
          return typeof option === 'string' ? option : option?.value
        })
        searchParams.set(paramName, terms.join())
      } else {
        searchParams.delete(paramName)
      }
      setSearchParams(searchParams)
    }
  }, [selected])

  const updateSearch = (term: string) => {
    setSearchText(term)
  }

  return (
    <FormControlStyled size="small" key={name}>
      <Select
        multiple
        displayEmpty
        onOpen={() => setSearchText('')}
        key={name}
        name={name}
        value={[name]}
        MenuProps={{
          autoFocus: false,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        renderValue={getRenderValue}
        onChange={(e) =>
          handleChange(
            Array.isArray(e.target.value) ? e.target.value : [e.target.value]
          )
        }
      >
        <FilterSearchTextField
          placeholder="Search"
          value={searchText}
          onChange={updateSearch}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          debounceTime={300}
          scale="small"
        />

        {filteredOptions.map((option) => (
          <MenuItem
            key={typeof option === 'string' ? option : option.value}
            value={typeof option === 'string' ? option : option.value}
            sx={{
              backgroundColor: selected.has(
                typeof option === 'string' ? option : (option.value as string)
              )
                ? '#e0e0e0'
                : 'transparent',
              '&:hover': {
                backgroundColor: selected.has(
                  typeof option === 'string' ? option : (option.value as string)
                )
                  ? '#e0e0e0'
                  : '#f5f5f5'
              }
            }}
          >
            <Typography>
              {typeof option === 'string' ? option : option.label}
            </Typography>
          </MenuItem>
        ))}
        {filteredOptions.length == 0 && (
          <MenuItem disabled>No {name.toLowerCase()} found</MenuItem>
        )}
      </Select>
    </FormControlStyled>
  )
}
