import { SubFormulaStatementFormat } from './Formula'
import { ActiveIngredientDisplaySection } from './FormulaIngredient'
import { Nutrient, NutrientType } from './Nutrient'
import { SupplementFacts } from './SupplementFacts'

export interface FormulaIngredientStatement {
  ingredientStatementPrefix?: FormulaNutritionFactLabelLangSpecificFields
  suggestedIngredientStatement?: FormulaNutritionFactLabelLangSpecificFields
  ingredientStatement?: string
}

export interface FormulaIngredientsFormatPreview {
  id: string
  name: string
  format: SubFormulaStatementFormat
}

export interface ActiveFormulaIngredientSectionDisplayPreview {
  id: string
  name: string
  displaySection: ActiveIngredientDisplaySection
}

export interface NutritionFactLabelItem {
  name: string
  amount: string | number
  unit: string
  dvPercentage?: number
}

export interface DisplayAmountOption {
  amount: string
}

export interface DisplayDvOption {
  amount: string
  notSignifcantSource?: boolean
  asteriskText?: string
}

export interface OverrideValues {
  amount?: string
  dv?: string
  nutrientDisplayName?: FormulaNutritionFactLabelLangSpecificFields
  perContainerAmount?: string
  perContainerDv?: string
}

export interface FormulaNutritionFactNutrient {
  nutrient: Nutrient
  displayAmountOptions: DisplayAmountOption[]
  displayDvOptions: DisplayDvOption[]
  displayNameOptions: string[] | FormulaNutritionFactLabelLangSpecificFields
  overrideValues?: OverrideValues
}

export interface FormulaNutritionFactNutrientLabelDisplay
  extends Omit<FormulaNutritionFactNutrient, 'displayNameOptions'> {
  displayNameOptions: FormulaNutritionFactLabelLangSpecificFields
}

export enum FormulaLabelStatementCaseType {
  ALL_CAPS = 'Uppercase',
  ALL_LOWERCASE = 'Lowercase',
  TITLE_CASE = 'Title case',
  SENTENCE_CASE = 'Sentence case',
  NOT_SET = 'Custom'
}

export enum FormulaNutritionFactLabelType {
  VERTICAL = 'Vertical',
  TABULAR = 'Tabular',
  LINEAR = 'Linear',
  DUAL = 'Dual'
}

export enum FormulaLabelType {
  FOOD = 'Food Label',
  SUPPLEMENT = 'Supplement Label (Beta)'
}

export enum FormulaNutritionFactLabelAgeGroup {
  ADULT = 'Adult',
  INFANT = 'Infant',
  CHILDREN_LESS_THAN_FOUR = 'Children Less than 4',
  PREGNANT_WOMEN = 'Pregnant Women',
  LACTACTING_WOMEN = 'Lactating Women'
}

export interface FormulaNutritionFactsPerContainer {
  containerWeight: number
  nutrients: FormulaNutritionFactNutrient[]
  calories: string
}

export enum FormulaNutritionFactLanguage {
  ENGLISH = 'english',
  FRENCH = 'french',
  SPANISH = 'spanish'
}

export const languageDisplayPriority: Record<
  FormulaNutritionFactLanguage,
  number
> = {
  [FormulaNutritionFactLanguage.ENGLISH]: 0,
  [FormulaNutritionFactLanguage.FRENCH]: 1,
  [FormulaNutritionFactLanguage.SPANISH]: 2
}

export type FormulaNutritionFactLabelLangSpecificFields = {
  [key in FormulaNutritionFactLanguage]?: string
}

export interface FormulaNutritionFact {
  servingsPerContainer: string
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: number
  servingWeightOverride: string
  containerWeight: number
  calories: string
  nutrients: FormulaNutritionFactNutrient[]
  type: FormulaNutritionFactLabelType
  ageGroup: FormulaNutritionFactLabelAgeGroup
  optionalNutrientsType: NutrientType[]
  perContainer?: FormulaNutritionFactsPerContainer
  showProteinPercentage: boolean
  forceShowProteinPercentage: boolean
  pdcaas: number
  applyPdcaas: boolean
  description: FormulaNutritionFactLabelLangSpecificFields
  ingredientStatement: FormulaNutritionFactLabelLangSpecificFields
  allergenStatement: FormulaNutritionFactLabelLangSpecificFields
  suggestedAllergenStatement: FormulaNutritionFactLabelLangSpecificFields
  allergenStatementPrefix: FormulaNutritionFactLabelLangSpecificFields
  regulationId: string
  dvBasedOnRounded: boolean
}

export interface FormulaNutritionFactLabelHeader {
  servingsPerContainer: string
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  type: FormulaNutritionFactLabelType
  ageGroup: FormulaNutritionFactLabelAgeGroup
  showProteinPercentage: boolean
  forceShowProteinPercentage: boolean
  pdcaas: number
  applyPdcaas: boolean
  description: FormulaNutritionFactLabelLangSpecificFields
  ingredientStatement: FormulaNutritionFactLabelLangSpecificFields
  allergenStatement: FormulaNutritionFactLabelLangSpecificFields
  regulationId: string
  servingWeightOverride: string
}

export interface FormulaNutritionFactEdits {
  allergens: string[]
  ingredientsStatement?: string
  nutritionFactLabelPreview: FormulaNutritionFact | SupplementFacts
  activeIngredientsSectionDisplay: ActiveFormulaIngredientSectionDisplayPreview[]
  isSupplement: boolean
}

export interface FormulaNFPOverride {
  nutrientId: string
  nutrientDisplayName?: FormulaNutritionFactLabelLangSpecificFields
  dv?: string
  amount?: string
  perContainerAmount?: string
  perContainerDv?: string
}
