import React from 'react'
import { NutrientsOverridesContainer } from './components/Nutrients/NutrientsOverridesContainer'
import { Overrides } from './Overrides'
import { LabelOverridesContainer } from './components/Label/LabelOverridesContainer'
import { ActiveIngredientsContainer } from './components/ActiveIngredients/ActiveIngredientsContainer'
import { useAppSelector } from 'state/hooks'

export const OverridesContainer: React.FC = () => {
  const supplement = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )

  return (
    <Overrides
      label={<LabelOverridesContainer />}
      nutrients={<NutrientsOverridesContainer />}
      activeIngredients={<ActiveIngredientsContainer />}
      supplement={supplement}
    />
  )
}
