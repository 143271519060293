import {
  Skeleton,
  TableCell,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { BoxRowBasic } from 'components/common'
import {
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import { OverrideTextField } from '../../common/OverrideTextField'

const UnitTypography = styled(Typography)`
  margin-left: 3px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.tertiary.main};
`

export interface NutrientRowProps {
  id: string
  originals: {
    displayName: string[]
    displayAmount: string
    displayDvPercentage: string
    unit: string
    perContainer?: {
      displayAmount: string
      displayDvPercentage: string
    }
  }
  availableLanguagesInOverrides?: FormulaNutritionFactLanguage[]
  overrides?: {
    amount?: string
    dvPercentage?: string
    name?: FormulaNutritionFactLabelLangSpecificFields
    perContainerAmount?: string
    perContainerDvPercentage?: string
    onChange: (
      id: string,
      name?: FormulaNutritionFactLabelLangSpecificFields,
      amount?: string,
      dvPercentage?: string,
      perContainerAmount?: string,
      perContainerDvPercentage?: string
    ) => void
  }
  disabledName?: boolean
  level?: number
  multiline?: boolean
  hideUnits?: boolean
}

export const NutrientRowSkeleton: React.FC<{ colSpan: number }> = ({
  colSpan
}) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Skeleton variant={'rectangular'} height={'19px'} />
      </TableCell>
    </TableRow>
  )
}

export const NutrientRow = ({
  id,
  originals,
  overrides,
  availableLanguagesInOverrides,
  level = 0,
  disabledName = false,
  multiline = false,
  hideUnits = false
}: NutrientRowProps) => {
  return (
    <TableRow key={id + '-nutrient-row'}>
      {/* Original nutrients. */}
      <TableCell
        align="left"
        sx={{
          padding: '4px',
          position: 'sticky',
          left: 0,
          zIndex: 2,
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)'
        }}
      >
        <Typography
          sx={{
            fontWeight: level > 0 ? '400' : '500',
            fontSize: '14px',
            marginLeft: level > 0 ? `${level * 12}px` : '0px'
          }}
        >
          {originals.displayName[0]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ padding: '4px' }}>
        <BoxRowBasic>
          <Typography fontSize={'14px'}>{originals.displayAmount}</Typography>
          <UnitTypography>{originals.unit}</UnitTypography>
        </BoxRowBasic>
      </TableCell>
      <TableCell align="left">
        <BoxRowBasic>
          {originals.displayDvPercentage && (
            <>
              <Typography fontSize={'14px'}>
                {originals.displayDvPercentage}
              </Typography>
              <UnitTypography>%</UnitTypography>
            </>
          )}
        </BoxRowBasic>
      </TableCell>

      {/* Overrides. */}
      {availableLanguagesInOverrides &&
        availableLanguagesInOverrides.length > 0 &&
        availableLanguagesInOverrides.map((lang, idx) => (
          <TableCell key={lang} align="left" sx={{ padding: '4px' }}>
            {!disabledName && (
              <OverrideTextField
                scale="small"
                value={overrides?.name?.[lang] ?? ''}
                onChange={(value) =>
                  overrides?.onChange(
                    id,
                    { ...overrides?.name, [lang]: value },
                    undefined,
                    undefined
                  )
                }
                multiline={multiline}
                originalValue={originals.displayName[idx]}
              />
            )}
          </TableCell>
        ))}
      <TableCell align="left" sx={{ padding: '4px' }}>
        <OverrideTextField
          scale="small"
          value={overrides?.amount || ''}
          onChange={(value) => {
            overrides?.onChange(id, undefined, value, undefined)
          }}
          endAdornment={
            !hideUnits && <UnitTypography>{originals.unit}</UnitTypography>
          }
          multiline={multiline}
          originalValue={originals.displayAmount}
        />
      </TableCell>
      <TableCell align="left" sx={{ padding: '4px' }}>
        {originals.displayDvPercentage && (
          <OverrideTextField
            scale="small"
            value={overrides?.dvPercentage || ''}
            onChange={(value) =>
              overrides?.onChange(id, undefined, undefined, value)
            }
            endAdornment={!hideUnits && <UnitTypography>%</UnitTypography>}
            multiline={multiline}
            originalValue={originals.displayDvPercentage}
          />
        )}
      </TableCell>
      {originals.perContainer && (
        <TableCell align="left" sx={{ padding: '4px' }}>
          <OverrideTextField
            scale="small"
            value={overrides?.perContainerAmount || ''}
            onChange={(value) =>
              overrides?.onChange(id, undefined, undefined, undefined, value)
            }
            endAdornment={
              !hideUnits && <UnitTypography>{originals.unit}</UnitTypography>
            }
            multiline={multiline}
            originalValue={originals.perContainer?.displayAmount}
          />
        </TableCell>
      )}
      {originals.perContainer && originals.displayDvPercentage && (
        <TableCell align="left" sx={{ padding: '4px' }}>
          <OverrideTextField
            scale="small"
            value={overrides?.perContainerDvPercentage || ''}
            onChange={(value) =>
              overrides?.onChange(
                id,
                undefined,
                undefined,
                undefined,
                undefined,
                value
              )
            }
            endAdornment={!hideUnits && <UnitTypography>%</UnitTypography>}
            multiline={multiline}
            originalValue={originals.perContainer?.displayDvPercentage}
          />
        </TableCell>
      )}
    </TableRow>
  )
}
