import { Chip, Typography } from '@mui/material'
import { TextField } from 'components/InputFields/TextField'
import styled from 'styled-components'
import { BoxRow } from '../../common'

export const FilterSearchTextField = styled(TextField)`
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: none;
  padding: 8px;
`

export const ChipCount = styled(Chip)`
  height: 18px;
`

export const FilterNameTypography = styled(Typography)``

export const FilterNameBox = styled(BoxRow)`
  gap: 4px;
`
