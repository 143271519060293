import { SelectOption } from 'components/common'
import { ModalContext } from 'components/Modal/ModalContext'
import {
  FormulaLabelStatementCaseType,
  FormulaLabelType,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import {
  resetFormulaRegulationsSettingsEdits,
  setFormulaRegulationsStatementCaseEdits
} from 'state/formulator/FormulatorSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getAvailableRegulations,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import {
  getFormulaIngredientStatementPreview,
  setFormulaIngredientsStatementFormatPreview
} from 'state/labels/statements/IngredientStatementsSlice'
import { Layout } from './Layout'
import { getCategories, getFormats, getTypes } from './LayoutConstraints'
import {
  mapFormulaIngredientsToActiveIngredientsSectionDisplay,
  mapFormulaIngredientsToStatementFormatsPreview
} from 'services/apis/formula/FormulaApiMapper'

const enum Field {
  regulationId = 'regulationId',
  category = 'ageGroup',
  format = 'type',
  servingSize = 'servingSize',
  servingsPerContainer = 'servingsPerContainer',
  containerWeight = 'containerWeight',
  ingredientStatement = 'ingredientStatement',
  description = 'description',
  labelType = 'isSupplement'
}

export const LayoutContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const { showConfirmationModal } = React.useContext(ModalContext)

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)

  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const selectedRegulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )
  const selectedCategory = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ageGroup
  )
  const selectedLabelType = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )
    ? FormulaLabelType.SUPPLEMENT
    : FormulaLabelType.FOOD
  const selectedFormat = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.type
  )
  const servingSize = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.servingSize
  )
  const servingsPerContainer = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.servingsPerContainer
  )
  const containerWeight = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.containerWeight
  )

  const ingredientStatementPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ingredientStatement
  )

  const suggestedIngredientStatementPreview = useAppSelector(
    (state) =>
      state.ingredientStatements.ingredientStatementPreview
        ?.suggestedIngredientStatement
  )
  const formulaIngredientsStatementFormatPreview = useAppSelector(
    (state) =>
      state.ingredientStatements.formulaIngredientsStatementFormatPreview
  )

  const description = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.description
  )

  const formulaRegulationsSettingsEdits = useAppSelector(
    (state) => state.formulator.formulaRegulationsSettingsEdits
  )

  const regulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )

  const formulaIngredients = useAppSelector(
    (state) => state.formulatorIngredients.formulaIngredients
  )

  const [categories, setCategories] = React.useState<SelectOption<string>[]>([])
  const [formats, setFormats] = React.useState<SelectOption<string>[]>([])
  const [types, setTypes] = React.useState<SelectOption<string>[]>([])
  const [clearedStatement, setClearedStatement] = React.useState<{
    [key: string]: boolean
  }>({})

  React.useEffect(() => {
    if (regulations.length === 0) {
      void dispatch(getAvailableRegulations())
    }
  }, [regulations.length])

  React.useEffect(() => {
    if (selectedRegulationId) {
      // Get the new available options for categories & format based on the regulation.
      const selectedRegulation = regulations.find(
        (reg) => reg.id === selectedRegulationId
      )
      const newCategories = getCategories(
        selectedRegulation?.name,
        selectedLabelType
      )

      const newFormats = getFormats(selectedRegulation?.name, selectedLabelType)
      const newTypes = getTypes(selectedRegulation?.name)

      setCategories(newCategories)
      setFormats(newFormats)
      setTypes(newTypes)

      // Check if the current category exists in the new regulation.
      const categoryExists = newCategories.some(
        (option) => option.value === selectedCategory
      )
      if (!categoryExists && newCategories.length > 0) {
        handleChange(Field.category, newCategories[0].value)
      } else if (!categoryExists) {
        handleChange(Field.category, '')
      }

      // Check if the current format exists in the new regulation.
      const formatExists = newFormats.some(
        (option) => option.value === selectedFormat
      )
      if (!formatExists && newFormats.length > 0) {
        handleChange(Field.format, newFormats[0].value)
      } else if (!formatExists) {
        handleChange(Field.format, '')
      }

      // Check if the current type exists in the new regulation.
      const typeExists = newTypes.some(
        (option) => option.value === selectedLabelType
      )
      if (!typeExists && newTypes.length > 0) {
        handleChange(Field.labelType, newTypes[0].value)
      } else if (!typeExists) {
        handleChange(Field.labelType, '')
      }
    }
  }, [
    selectedRegulationId,
    selectedCategory,
    selectedFormat,
    selectedLabelType
  ])

  const currentFormulaRegulationStatementCase = React.useMemo(() => {
    let statementCase = formulaRegulationsSettingsEdits.find(
      (regulation) => regulation.regulationId === selectedRegulationId
    )?.statementCase
    if (statementCase === undefined) {
      statementCase = FormulaLabelStatementCaseType.NOT_SET
    }
    return statementCase
  }, [selectedRegulationId, formulaRegulationsSettingsEdits])

  const currentFormulaRegulationSubIngredientStatementCase =
    React.useMemo(() => {
      let subIngredientStatementCase = formulaRegulationsSettingsEdits.find(
        (regulation) => regulation.regulationId === selectedRegulationId
      )?.subIngredientStatementCase
      if (subIngredientStatementCase === undefined) {
        subIngredientStatementCase = FormulaLabelStatementCaseType.NOT_SET
      }
      return subIngredientStatementCase
    }, [selectedRegulationId, formulaRegulationsSettingsEdits])

  React.useEffect(() => {
    if (!formulaId) return
    void dispatch(
      getFormulaIngredientStatementPreview({
        companyId: currentCompany.id,
        formulaId: formulaId,
        withSuggestions: true,
        regulationId: selectedRegulationId ? selectedRegulationId : undefined,
        isSupplement: selectedLabelType === FormulaLabelType.SUPPLEMENT,
        statementCase: currentFormulaRegulationStatementCase,
        subIngredientStatementCase:
          currentFormulaRegulationSubIngredientStatementCase,
        formulaIngredientsStatementFormatPreview
      })
    )
  }, [
    selectedRegulationId,
    selectedLabelType,
    formulaIngredientsStatementFormatPreview,
    currentFormulaRegulationStatementCase,
    currentFormulaRegulationSubIngredientStatementCase
  ])

  React.useEffect(() => {
    resetFormulaRegulationsSettingsEdits()
  }, [selectedRegulationId])

  React.useEffect(() => {
    dispatch(
      setFormulaIngredientsStatementFormatPreview(
        mapFormulaIngredientsToStatementFormatsPreview(
          selectedLabelType === FormulaLabelType.SUPPLEMENT,
          formulaIngredients
        )
      )
    )
    dispatch(
      setNutritionFactLabelEdits({
        ...nutritionFactLabelEdits,
        activeIngredientsSectionDisplay:
          mapFormulaIngredientsToActiveIngredientsSectionDisplay(
            selectedLabelType === FormulaLabelType.SUPPLEMENT,
            formulaIngredients
          )
      })
    )
  }, [selectedLabelType])

  const handleChange = (
    field: string,
    value:
      | string
      | number
      | null
      | FormulaNutritionFactLabelLangSpecificFields
      | boolean
  ) => {
    if (field === Field.regulationId) {
      showConfirmationModal({
        title: 'Changing Regulation',
        message:
          'Changing the regulation will discard any unsaved changes. Would you like to continue?',
        yesText: 'Continue',
        noText: 'Cancel',
        onYesClicked: () => {
          void dispatch(
            setNutritionFactLabelEdits({
              ...nutritionFactLabelEdits,
              nutritionFactLabelPreview: {
                ...nutritionFactLabelEdits.nutritionFactLabelPreview,
                [Field.regulationId]: value as string
              }
            })
          )
        }
      })
    } else if (field === Field.labelType) {
      void dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          [field]: value === FormulaLabelType.SUPPLEMENT,
          nutritionFactLabelPreview: {
            ...nutritionFactLabelEdits.nutritionFactLabelPreview
          }
        })
      )
    } else {
      void dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          nutritionFactLabelPreview: {
            ...nutritionFactLabelEdits.nutritionFactLabelPreview,
            [field]: value
          }
        })
      )
    }
  }

  const getServingSizePlaceholder = (
    language: FormulaNutritionFactLanguage
  ): string => {
    switch (language) {
      case FormulaNutritionFactLanguage.ENGLISH:
        return 'e.g. 1 cup, 1 tbsp'
      case FormulaNutritionFactLanguage.FRENCH:
        return 'e.g. 1 tasse, 1 cuillère à soupe'
      case FormulaNutritionFactLanguage.SPANISH:
        return 'e.g. 1 taza, 1 cucharada'
      default:
        return 'e.g. 1 cup, 1 tbsp'
    }
  }

  const suggestedIngredientsStatementPreview = React.useMemo(() => {
    return (
      suggestedIngredientStatementPreview || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [suggestedIngredientStatementPreview])

  const handleAddFormulaRegulationStatementCase = (
    statementCase: FormulaLabelStatementCaseType
  ) => {
    void dispatch(
      setFormulaRegulationsStatementCaseEdits({
        regulationId: selectedRegulationId,
        statementCase: statementCase
      })
    )
  }

  const currentRegulation = React.useMemo(() => {
    return regulations.find(
      (regulation) => regulation.id === selectedRegulationId
    )
  }, [selectedRegulationId, regulations])

  return (
    <Layout
      regulation={{
        options: regulations.map((regulation) => {
          return {
            label: regulation.name,
            value: regulation.id
          }
        }),
        value: selectedRegulationId,
        onChange: (v) => handleChange(Field.regulationId, v)
      }}
      selectedRegulationName={
        regulations.find((regulation) => regulation.id === selectedRegulationId)
          ?.name
      }
      category={{
        options: categories,
        value: categories.length > 0 ? selectedCategory : '',
        onChange: (v) => handleChange(Field.category, v)
      }}
      format={{
        options: formats,
        value: formats.length > 0 ? selectedFormat : '',
        onChange: (v) => handleChange(Field.format, v)
      }}
      type={{
        options: types,
        value: types.length > 0 ? selectedLabelType : '',
        onChange: (v) => handleChange(Field.labelType, v)
      }}
      servingSize={
        servingSize
          ? Object.keys(servingSize).map((language) => {
              return {
                language: language as FormulaNutritionFactLanguage,
                value:
                  servingSize[language as FormulaNutritionFactLanguage] || '',
                onChange: (v: string) =>
                  handleChange(Field.servingSize, {
                    ...servingSize,
                    [language]: v
                  })
              }
            })
          : []
      }
      servingsPerContainer={{
        value: servingsPerContainer,
        onChange: (v) => handleChange(Field.servingsPerContainer, v)
      }}
      containerWeight={{
        value: containerWeight,
        onChange: (v) => handleChange(Field.containerWeight, v),
        visible: selectedFormat === FormulaNutritionFactLabelType.DUAL
      }}
      getServingSizePlaceholder={getServingSizePlaceholder}
      ingredients={Object.keys(ingredientStatementPreview).map((language) => {
        const languageKey = language as FormulaNutritionFactLanguage
        return {
          language: language as FormulaNutritionFactLanguage,
          value:
            ingredientStatementPreview[languageKey] ||
            (clearedStatement[languageKey]
              ? ''
              : suggestedIngredientsStatementPreview[languageKey]) ||
            '',
          suggestedValue:
            suggestedIngredientsStatementPreview[
              language as FormulaNutritionFactLanguage
            ],
          onChange: (v: string) => {
            handleChange(Field.ingredientStatement, {
              ...ingredientStatementPreview,
              [language]: v
            })
            // Explicitly cleared.
            if (v === '') {
              setClearedStatement((prevState) => ({
                ...prevState,
                [languageKey]: true
              }))
            } else {
              setClearedStatement((prevState) => ({
                ...prevState,
                [languageKey]: false
              }))
            }
          },
          onBlur: () => setClearedStatement({})
        }
      })}
      descriptions={Object.keys(description).map((language) => {
        return {
          language: language as FormulaNutritionFactLanguage,
          value: description[language as FormulaNutritionFactLanguage] || '',
          onChange: (v: string) =>
            handleChange(Field.description, {
              ...description,
              [language]: v
            })
        }
      })}
      statementCase={{
        options: Object.values(FormulaLabelStatementCaseType).map((value) => ({
          label: value,
          value: value as FormulaLabelStatementCaseType
        })),
        value:
          currentRegulation?.name === RegulationName.CANADA
            ? FormulaLabelStatementCaseType.SENTENCE_CASE
            : currentFormulaRegulationStatementCase,
        onChange: handleAddFormulaRegulationStatementCase,
        disabled: currentRegulation?.name === RegulationName.CANADA
      }}
    />
  )
}
