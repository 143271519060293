import React, { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'
import { MainColors } from 'styles/colors'

type BannerProps = {
  type: 'info' | 'warning' | 'error'
  width?: string
  children: ReactNode
  sx?: SxProps
}

export const Banner: React.FC<BannerProps> = ({
  type,
  width = '100%',
  children,
  sx
}) => {
  let backgroundColor
  switch (type) {
    case 'info':
      backgroundColor = MainColors.info
      break
    case 'warning':
      backgroundColor = MainColors.lightWarning
      break
    case 'error':
      backgroundColor = MainColors.danger
      break
  }

  return (
    <Box
      width={width}
      bgcolor={backgroundColor}
      borderRadius={'8px'}
      display="inline-block"
      padding={'8px'}
      sx={sx}
    >
      {children}
    </Box>
  )
}
