import { BasicUser } from 'models/User'
import {
  ReviewerApiResponse,
  TaskApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { ActionStatus, AvatarAction } from '../../../../components/Avatar'
import {
  Reviewer,
  ReviewerOption
} from './ReviewItem/components/Reviewers/Reviewers'
import { ReviewItem } from './ReviewList'
import { BasicUserApiResponse } from 'services/apis/user/UserApiResponse'

const isTaskChecked = (
  reviewers: ReviewerApiResponse[],
  currentUserId: string
): boolean => {
  // If the current user is a reviewer and approved the task or if all reviewers approved, it is checked.
  if (reviewers.length === 0) {
    return false
  }
  return (
    reviewers.some(
      (reviewer) => reviewer.reviewer.id === currentUserId && reviewer.approved
    ) || reviewers.every((reviewer) => reviewer.approved)
  )
}

const isTaskCompleted = (reviewers: ReviewerApiResponse[]): boolean => {
  return (
    reviewers.every((reviewer) => reviewer.approved) && reviewers.length > 0
  )
}

const getAvatarAction = (
  reviewer: ReviewerApiResponse
): AvatarAction | undefined => {
  return reviewer.approved
    ? { status: ActionStatus.APPROVED, date: reviewer.approvedAt || '' }
    : undefined
}

const getReviewersFromTask = (
  reviewers: ReviewerApiResponse[],
  owner: BasicUserApiResponse
): Reviewer[] => {
  // Find the owner in the reviewer and remove them.
  let ownerReviewer: Reviewer = {
    id: owner.id,
    name: `${owner.firstName} ${owner.lastName}`,
    imageUrl: owner.imagePath,
    removable: false,
    owner: true,
    action: undefined
  }

  const otherReviewers: Reviewer[] = reviewers.map((reviewer) => ({
    id: reviewer.reviewer.id,
    name: `${reviewer.reviewer.firstName} ${reviewer.reviewer.lastName}`,
    imageUrl: reviewer.reviewer.imagePath,
    removable: true,
    action: getAvatarAction(reviewer)
  }))

  // In the otherReviewers, extract and remove the owner.
  const ownerIndex = otherReviewers.findIndex(
    (reviewer) => reviewer.id === owner.id
  )
  if (ownerIndex !== -1) {
    // Extract the action.
    ownerReviewer = {
      ...ownerReviewer,
      action: otherReviewers.splice(ownerIndex, 1)[0].action
    }
  }

  // Insert the owner reviewer at the beginning.
  otherReviewers.unshift(ownerReviewer)

  return otherReviewers
}

const getReviewerOptions = (
  allReviewerOptions: BasicUser[]
): ReviewerOption[] => {
  return allReviewerOptions.map((reviewer) => ({
    id: reviewer.id,
    name: reviewer.firstName + ' ' + reviewer.lastName,
    imageUrl: reviewer.imagePath
  }))
}

const isCheckable = (
  reviewers: ReviewerApiResponse[],
  currentUserId: string,
  createdBy: string
): boolean => {
  return (
    reviewers.some((reviewer) => reviewer.reviewer.id === currentUserId) ||
    createdBy === currentUserId
  )
}

const isEditable = (createdBy: string, currentUserId: string): boolean => {
  return true // For now.
}

export const getReviewItemsFromTasks = (
  tasks: TaskApiResponse[],
  allReviewerOptions: BasicUser[],
  currentUserId: string
): ReviewItem[] => {
  return tasks.map((task: TaskApiResponse) => ({
    id: task.id,
    name: task.name,
    checked: isTaskChecked(task.reviewers, currentUserId),
    completed: isTaskCompleted(task.reviewers),
    reviewerOptions: getReviewerOptions(allReviewerOptions),
    reviewers: getReviewersFromTask(task.reviewers, task.createdBy),
    checkable: isCheckable(task.reviewers, currentUserId, task.createdBy.id),
    editable: isEditable(task.createdBy.id, currentUserId)
  }))
}
