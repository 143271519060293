import { FormulaNutrientsScale } from 'models/Formula'
import {
  FormulaNFPOverride,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient
} from 'models/FormulaLabel'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getFormulaNutrients,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { NutrientsOverrides } from './NutrientsOverides'
import { toTableNutrientsRowGroup } from './OverridesNutrientsMapper'
import { NutrientRequirement, NutrientType } from 'models/Nutrient'

export const NutrientsOverridesContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((sate) => sate.formulator.formula.id)

  const [overridesMap, setOverridesMap] = React.useState<Record<
    string,
    FormulaNFPOverride
  > | null>(null)

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const actualFormulaNutrients = useAppSelector(
    (state) => state.formulator.formulaNutrients
  )

  const formulaNutrients = useAppSelector(
    (state) => state.nutritionFactLabels.formulaNutrients
  )

  const ageGroup = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ageGroup
  )
  const regulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )

  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )

  const nutritionFactsPreviewNutrients = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.nutrients
  )

  const nutritionFactsPreviewPerContainerNutrients = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.perContainer?.nutrients
  )

  const loadingNFPLabel = useAppSelector(
    (state) => state.nutritionFactLabels.loadingNFPLabel
  )

  const regulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )
  const selectedRegulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )
  const previewLabelType = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.type
  )
  const currentRegulation = React.useMemo(() => {
    return regulations.find(
      (regulation) => regulation.id === selectedRegulationId
    )
  }, [selectedRegulationId, regulations])

  const handleOverrideChange = React.useCallback(
    (
      id: string,
      name?: FormulaNutritionFactLabelLangSpecificFields,
      amount?: string,
      dv?: string,
      perContainerAmount?: string,
      perContainerDv?: string
    ) => {
      setOverridesMap((prevOverridesMap) => {
        const currentMap = prevOverridesMap ?? {}
        return {
          ...currentMap,
          [id]: {
            nutrientId: id,
            nutrientDisplayName: name ?? currentMap[id]?.nutrientDisplayName,
            amount: amount ?? currentMap[id]?.amount,
            dv: dv ?? currentMap[id]?.dv,
            perContainerAmount:
              perContainerAmount ?? currentMap[id]?.perContainerAmount,
            perContainerDv: perContainerDv ?? currentMap[id]?.perContainerDv
          }
        }
      })
    },
    []
  )

  React.useEffect(() => {
    // If regulation Id changes, clear the overrides map.
    setOverridesMap(null)
  }, [regulationId])

  React.useEffect(() => {
    // Only populates it when it's empty.
    if (
      overridesMap === null &&
      !loadingNFPLabel &&
      nutritionFactsPreviewNutrients
    ) {
      setOverridesMap(
        (
          nutritionFactsPreviewNutrients as FormulaNutritionFactNutrient[]
        ).reduce((acc, n) => {
          acc[n.nutrient.id] = {
            nutrientId: n.nutrient.id,
            nutrientDisplayName: n.overrideValues?.nutrientDisplayName,
            amount: n.overrideValues?.amount,
            dv: n.overrideValues?.dv,
            perContainerAmount: n.overrideValues?.perContainerAmount,
            perContainerDv: n.overrideValues?.perContainerDv
          }
          return acc
        }, {} as Record<string, FormulaNFPOverride>)
      )
    }
  }, [nutritionFactsPreviewNutrients])

  React.useEffect(() => {
    const updatedEdits = {
      ...nutritionFactLabelEdits,
      nutritionFactLabelPreview: {
        ...nutritionFactLabelEdits.nutritionFactLabelPreview,
        nutrients:
          nutritionFactLabelEdits.nutritionFactLabelPreview.nutrients.map(
            (n) => {
              const overrides = overridesMap?.[n.nutrient.id]
              if (overrides) {
                return {
                  ...n,
                  overrideValues: {
                    amount: overrides.amount,
                    dv: overrides.dv,
                    nutrientDisplayName: overrides.nutrientDisplayName,
                    perContainerAmount: overrides.perContainerAmount,
                    perContainerDv: overrides.perContainerDv
                  }
                }
              }
              return n
            }
          )
      }
    }

    if (nutritionFactLabelEdits.nutritionFactLabelPreview.perContainer) {
      updatedEdits.nutritionFactLabelPreview.perContainer = {
        ...nutritionFactLabelEdits.nutritionFactLabelPreview.perContainer,
        nutrients:
          nutritionFactLabelEdits.nutritionFactLabelPreview.perContainer.nutrients.map(
            (n) => {
              const overrides = overridesMap?.[n.nutrient.id]
              if (overrides) {
                return {
                  ...n,
                  overrideValues: {
                    amount: overrides.amount,
                    dv: overrides.dv,
                    nutrientDisplayName: overrides.nutrientDisplayName,
                    perContainerAmount: overrides.perContainerAmount,
                    perContainerDv: overrides.perContainerDv
                  }
                }
              }
              return n
            }
          )
      }
    }

    void dispatch(setNutritionFactLabelEdits(updatedEdits))
  }, [overridesMap])

  const visibleNutrients = React.useMemo(() => {
    // Get all the mandatory nutrients types from the preview.
    const nutrients = new Set(
      nutritionFactLabelEdits.nutritionFactLabelPreview.nutrients
        .filter((n) => n.nutrient.requirement === NutrientRequirement.MANDATORY)
        .map((n) => n.nutrient.type)
    )

    // Add the optional nutrient types that are selected.
    nutritionFactLabelEdits.nutritionFactLabelPreview.optionalNutrientsType.forEach(
      (type) => nutrients.add(type)
    )

    // Return the set of visible nutrients.
    return nutrients
  }, [
    nutritionFactLabelEdits.nutritionFactLabelPreview.optionalNutrientsType,
    nutritionFactLabelEdits.nutritionFactLabelPreview.nutrients
  ])

  const nutrientTableRowGroups = React.useMemo(() => {
    const uneditableNames = new Set<NutrientType>([
      NutrientType.CALORIES,
      NutrientType.ADDED_SUGAR,
      NutrientType.FOLATE_DFE,
      NutrientType.TRANS_FATTY_ACID
    ])

    if (nutritionFactLabelEdits.isSupplement) {
      uneditableNames.delete(NutrientType.CALORIES)
    }

    if (formulaNutrients) {
      return toTableNutrientsRowGroup(
        formulaNutrients,
        overridesMap ?? {},
        handleOverrideChange,
        nutritionFactsPreviewNutrients,
        nutritionFactsPreviewPerContainerNutrients,
        visibleNutrients,
        uneditableNames
      )
    }
    return []
  }, [
    formulaNutrients,
    overridesMap,
    handleOverrideChange,
    visibleNutrients,
    nutritionFactsPreviewNutrients,
    nutritionFactsPreviewPerContainerNutrients,
    nutritionFactLabelEdits.isSupplement
  ])

  const availableLanguagesInOverrides: FormulaNutritionFactLanguage[] =
    React.useMemo(() => {
      if (currentRegulation?.name !== undefined) {
        switch (currentRegulation.name) {
          case RegulationName.FDA:
            return [FormulaNutritionFactLanguage.ENGLISH]
          case RegulationName.CANADA:
            return [
              FormulaNutritionFactLanguage.ENGLISH,
              FormulaNutritionFactLanguage.FRENCH
            ]
          default:
            return [FormulaNutritionFactLanguage.ENGLISH]
        }
      }
      return [FormulaNutritionFactLanguage.ENGLISH]
    }, [currentRegulation])

  React.useEffect(() => {
    if (formulaId && regulationId && ageGroup) {
      void dispatch(
        getFormulaNutrients({
          companyId: currentCompany.id,
          formulaId: formulaId,
          scale: FormulaNutrientsScale.SERVING_SIZE,
          ageGroup: ageGroup,
          regulationId: regulationId
        })
      )
    }
  }, [
    formulaId,
    regulationId,
    ageGroup,
    actualFormulaNutrients,
    nutritionFactLabelEdits.isSupplement
  ])

  return (
    <NutrientsOverrides
      nutrientTableRowGroups={nutrientTableRowGroups}
      availableLanguagesInOverrides={availableLanguagesInOverrides}
      showPerContainerFields={
        currentRegulation?.name === RegulationName.FDA &&
        previewLabelType === FormulaNutritionFactLabelType.DUAL
      }
      hideUnits={nutritionFactLabelEdits.isSupplement}
      multiline={nutritionFactLabelEdits.isSupplement}
      loading={loadingNFPLabel}
    />
  )
}
