import { Box } from '@mui/material'
import React from 'react'
import Draggable, { DraggableEvent } from 'react-draggable'
import { ReactComponent as PinSvg } from 'assets/LabelProofDesignPin.svg'
import { ReactComponent as HighlightedPinSvg } from 'assets/LabelProofDesignActivatedPin.svg'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'

export interface DraggablePin {
  id: string
  x: number
  y: number
  highlighted: boolean
  hidden: boolean
  draggable: boolean
  user?: {
    name: string
    avatarSrc: string
  }
  commentId?: string
}

interface PinProps extends DraggablePin {
  scale: number
  updatePosition: (x: number, y: number, id: string) => void
}

// Compensate for the difference between the tip of the pin and the top left corner of the pin container
enum compensation { 
  X = 42,
  Y = 63
}

export const Pin: React.FC<PinProps> = ({
  id,
  x,
  y,
  scale,
  updatePosition,
  highlighted,
  hidden,
  user,
  draggable
}) => {
  if (hidden) {
    return null
  }
  return (
    <Draggable
      scale={scale}
      position={{ x: x - compensation.X, y: y - compensation.Y }}
      onStop={(e, data) =>
        updatePosition(data.x + compensation.X, data.y + compensation.Y, id)
      }
      onDrag={(e: DraggableEvent) => e.stopPropagation()}
      disabled={!draggable}
    >
      <Box
        sx={{
          position: 'absolute',
          height: '60px',
          width: '52px',
          top: '2px',
          left: '12px'
        }}
        onContextMenu={(e) => e.stopPropagation()}
      >
        <Box
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            top: '0',
            left: '0'
          }}
        >
          {highlighted ? <HighlightedPinSvg /> : <PinSvg />}
          <Box
            sx={{
              position: 'absolute',
              top: -5,
              left: -1.5,
              transform: 'translate(37%, 35%)'
            }}
          >
            {user && <AvatarInitials
              variant="circle"
              name={user.name}
              avatarSrc={user.avatarSrc}
              size={36}
            />}
          </Box>
        </Box>
      </Box>
    </Draggable>
  )
}
