import { SupplementFacts } from 'models/SupplementFacts'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { ActiveIngredients } from './ActiveIngredients'
import { ActiveIngredientRowProps } from './components/ActiveIngredientRow'
import { ActiveIngredientDisplaySection } from 'models/FormulaIngredient'
import { setNutritionFactLabelEdits } from 'state/labels/nutritions/NutritionFactLabelsSlice'

export const ActiveIngredientsContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const allActiveIngredients = useAppSelector(
    (state) =>
      (
        state.nutritionFactLabels.nutritionFactLabelEdits
          .nutritionFactLabelPreview as SupplementFacts
      ).activeIngredients
  )
  const loading = useAppSelector((state) => state.nutritionFactLabels.loading)
  const supplementFactsPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview as SupplementFacts
  )
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )

  const visibleActiveIngredients = React.useMemo(() => {
    return (
      allActiveIngredients?.filter((ai) =>
        [
          ActiveIngredientDisplaySection.ACTIVE_INGREDIENTS,
          ActiveIngredientDisplaySection.BOTH
        ].includes(ai.displaySection)
      ) || []
    )
  }, [allActiveIngredients])

  const handleChange = (id: string, name?: string, amount?: string) => {
    const newSupplementFacts = { ...supplementFactsPreview }
    newSupplementFacts.activeIngredients =
      newSupplementFacts.activeIngredients?.map((ai) => {
        if (ai.id === id) {
          return {
            ...ai,
            overrideValue: {
              name: name ?? ai.overrideValue?.name,
              amount: amount ?? ai.overrideValue?.amount
            }
          }
        }
        return ai
      })
    void dispatch(
      setNutritionFactLabelEdits({
        ...nutritionFactLabelEdits,
        nutritionFactLabelPreview: newSupplementFacts
      })
    )
  }

  const activeIngredientsTableRows: ActiveIngredientRowProps[] =
    visibleActiveIngredients.map((activeIngredient) => ({
      activeIngredient: {
        id: activeIngredient.id,
        name: activeIngredient.ingredientStatement,
        amount: activeIngredient.amountInMeasurement,
        unit: activeIngredient.measurement.unit
      },
      overrides: {
        name: activeIngredient.overrideValue?.name,
        amount: activeIngredient.overrideValue?.amount,
        onChange: handleChange
      }
    }))

  return (
    <ActiveIngredients
      loading={loading}
      activeIngredientsTableRows={activeIngredientsTableRows}
    />
  )
}
