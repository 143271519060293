import { Box } from '@mui/material'
import React from 'react'

interface OverridesProps {
  label: React.ReactNode
  nutrients: React.ReactNode
  activeIngredients: React.ReactNode
  supplement?: boolean
}

export const Overrides: React.FC<OverridesProps> = ({
  label,
  nutrients,
  activeIngredients,
  supplement
}) => {
  return (
    <Box sx={{ height: '100%' }}>
      {label}
      {nutrients}
      {supplement && activeIngredients}
    </Box>
  )
}
