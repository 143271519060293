import React from 'react'
import { NumberField, NumberFieldProps } from './NumberField'
import { validatePercentage } from './validation'
import { Typography, useTheme } from '@mui/material'

export interface PercentageFieldProps
  extends Omit<NumberFieldProps, 'validation' | 'value'> {
  /**
   * A number between 0 and 1 that represents the percentage value.
   */
  value: number | undefined
}

/**
 * An extension of the NumberField that formats the input as a percentage.
 */
export const PercentageField: React.FC<PercentageFieldProps> = ({
  value,
  onChange,
  ...props
}) => {
  const theme = useTheme()
  // Display the number in percentage format.
  const displayedValue = value ? value * 100 : 0
  const handleChange = (value: number) => {
    // Convert the percentage to a number between 0 and 1.
    onChange(value / 100)
  }

  return (
    <NumberField
      {...props}
      value={displayedValue}
      onChange={handleChange}
      additionalValidators={[validatePercentage]}
      endAdornment={
        <Typography marginLeft={'0.25em'} color={theme.palette.tertiary.main}>
          %
        </Typography>
      }
      validation=">=0"
    />
  )
}
